




const initState = {
    cart: [
        
        
        ],
    shipping : {"pickup": 0, "standard": 20, "express": 40, "ultimate": 80} ,
}




const rootReducer = (state = initState, action) => {
    
    switch(action.type) {
        

        // case 'ADD'
        case 'ADD' :

        var inCart = -1;

        for (let i=0; i < action.state.length; i++) {
            
            // Product Page:  to add/ deduct
            if (action.state[i]["cartItemProductName"] == action.payload.itemName ) {
                
                const modified = { cartItemProductID: action.payload.itemID, cartItemProductName: action.payload.itemName, src: action.payload.itemIMG, cartItemPrice: action.payload.itemPrice, cartItemQuantity: (action.state[i]["cartItemQuantity"]) + action.payload.itemQuantity }
                    
                const beforeI = action.state.slice(0, i);
                const afterI = action.state.slice(i+1,(action.state.length)) ;
                    
                const updateNewArray = beforeI.concat(modified, afterI);
                state = { cart: updateNewArray };
                inCart = 1;
                break;

            }

        }

        if (inCart == -1) {
        // Product Page:  to add NEW ITEM
            const newItem = { cartItemProductID: action.payload.itemID, cartItemProductName: action.payload.itemName, src: action.payload.itemIMG, cartItemPrice: action.payload.itemPrice, cartItemQuantity: action.payload.itemQuantity }
                    
            const init = state.cart;

            const updateNewArray = init.concat(newItem);;
            state = { cart: updateNewArray };
        
        }
            
        


        // case 'Update'
        case 'UPDATE' :
            
            for (let i=0; i < action.state.length; i++) {

                // Product Page:  to add/ deduct
                if (action.state[i]["cartItemProductID"] == action.payload.itemID ) {

                    const modified = { cartItemProductID: action.payload.itemID, cartItemProductName: action.payload.itemName, src: action.payload.itemIMG, cartItemPrice: action.payload.itemPrice, cartItemQuantity: (action.state[i]["cartItemQuantity"]) + action.payload.itemQuantity }
                    
                    const beforeI = action.state.slice(0, i);
                    const afterI = action.state.slice(i+1,(action.state.length)) ;
                    
                    const updateNewArray = beforeI.concat(modified, afterI);
                    state = { cart: updateNewArray };
                    
                    console.log(state);
                }
                
            }


                


        // case 'Deduct'

        case 'DEDUCT': {
            
            // CartItemContainer:  to add

            for (let i=0; i < action.state.length; i++) {
                
                if (action.state[i]["cartItemQuantity"] > 1 ) {

                    if (action.state[i]["cartItemProductID"] == action.payload.itemID) {
                        const modified = { cartItemProductID: action.payload.itemID, cartItemProductName: action.payload.itemName, src: action.payload.itemIMG, cartItemPrice: action.payload.itemPrice, cartItemQuantity:  (action.state[i]["cartItemQuantity"]) + action.payload.itemQuantity }
                    
                        const beforeI = action.state.slice(0, i);
                        const afterI = action.state.slice(i+1,(action.state.length)) ;
                    
                        const updatedArray = beforeI.concat(modified , afterI);
                        state = { ...state, cart: updatedArray };
                    
                        console.log(state);
                    }
                }
                
                if (action.state[i]["cartItemQuantity"] == 1) {
                    state = { ...state};
                }
        
            }
            
        }



        case 'REMOVE': {

            const updatedCart = state.cart.filter((item, index) => index !== Number(action.payload.itemIndex));

            return {
                ...state,
                cart: updatedCart,
            };
        }
        

        case 'CLEAR': {

            const updatedCart = action.payload.array;

            return {
                ...state,
                cart: updatedCart,
            };
        }


        default:
            return state;
        }


};


// export dispatch methods
export default rootReducer

