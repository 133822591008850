import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Routes, Link , useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import './App.css';
import Home from './Home';
import NavigationBar from './NavigationBar';
import AboutUs from './AboutUs';
import Stores from './Stores';
import AllProducts from './AllProducts';
import Promotions from './Promotions';
import Checkout  from './Checkout';
import OrderDetailsPage from './unused/OrderDetailsPage';
import Product from './Product';
import Holder from './Holder';
import PaymentSuccessful from './PaymentSuccessful';
import PaymentSuccessPage from './unused/PaymentSuccessPage';


const size = {
  mobile: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}
export const mobile = (inner) => css`
  @media (max-width: ${size.mobile}) {
    ${inner};
  }
`;
export const tablet= (inner) => css`
  @media (min-width: ${size.tablet}) {
    ${inner};
  }
`;
export const desktop= (inner) => css`
  @media (min-width: ${size.desktop}) {
    ${inner};
  }
`;
export const laptop= (inner) => css`
  @media (min-width: ${size.laptop}) {
    ${inner};
  }
`;
const StyledDiv = styled.div`
    ${mobile(css`
    width:300px;
      `)};
      
    ${tablet(css`
    width:740px;
    `)};

    ${desktop(css`
    width:1000px;
    `)};
`;



function App(props) {

    //  
    const location = useLocation();

    //  props.cart   'Subtotal'
    var cartInitSubtotal =  0 ;
    for (let i=0; i < props.state_cart.length; i++) {
       cartInitSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
    }



    // *******        ANY UPDATES of props.cart      ***********
    //    Update state of 'orderSubtotal' on this Top Parent Component, once ANY UPDATES through Redux's state dispatch functions  *****
    const [ orderSubtotal, setOrderSubtotal ] = useState(cartInitSubtotal);

    // () Update cartItems, passing down to child
    const [ cartItems, setCartItems ] = useState(props.state_cart);

    // show NavBar, checking location.name
    const [onCheckout, setOnCheckout] = useState(false);

    
    useEffect(() => {
      //    ()  1. set cartItems
      setCartItems(props.state_cart);

      //  ****   2. Set props.state.cart  Subtotal
      var cartCurrentSubtotal =  0 ;
      for (let i=0; i < props.state_cart.length; i++) {
        cartCurrentSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
      }
      setOrderSubtotal(cartCurrentSubtotal);

      // 3. Set <Nav> visible / hidden
      setOnCheckout(location.pathname.startsWith('/checkout/'));

    } , [props.state_cart, location.pathname]);

    

    for (let i=0; i < props.state_cart.length ; i++) {
      // console.log( " \t*** props.state.cart: " + "# " + i +  ", "  + props.state_cart[i]["cartItemProductName"] +  ", Qt: " + props.state_cart[i]["cartItemQuantity"]) ;
    };



   



    // () Parent State:  Update cartItems: 'ADD'
    const handleAddCartItems = ( valueProductID, valueProductName, valueProductIMG, valueProductPrice, valueQuantity) => {

      var inCart = -1;
      const initArray = cartItems;

      for (let i=0; i < initArray.length; i++) {

        if (initArray[i]["cartItemProductID"] == valueProductID ) {
          inCart = 1;

          const modified = { cartItemProductID: valueProductID, cartItemProductName: valueProductName, src: valueProductIMG, cartItemPrice: valueProductPrice, cartItemQuantity:  (initArray[i]["cartItemQuantity"]) + valueQuantity }

          const beforeI = initArray.slice(0, i);
          const afterI = initArray.slice(i+1,(initArray.length)) ;
          const updatedArray = beforeI.concat(modified , afterI);
          

          // Update this Top-level Superclass Component state variable cartItems
          setCartItems(updatedArray);

        }

      }
      
      // No current items
      
      if (inCart == -1) {
        const updatedArray = [ ...initArray, { cartItemProductID: valueProductID, cartItemProductName: valueProductName, src: valueProductIMG, cartItemPrice: valueProductPrice, cartItemQuantity: valueQuantity } ]
        
        for (let i=0; i < updatedArray.length; i++) {
          console.log(" Insert New Item:  " + updatedArray[updatedArray.length-1]["cartItemProductName"]);
        }
        setCartItems(updatedArray);
      }


    }

    // () Parent State:  Update cartItems: 'REMOVE'
    const handleRemoveCartItems = ( index ) => {
      const initArray = cartItems;
      // Modify cartItem state variable ; Reset Parent Component state (variables)
      const beforeI = initArray.slice(0, index);
      const afterI = initArray.slice(index+1,(initArray.length)) ;
      const updatedArray = beforeI.concat(afterI);

      setCartItems(updatedArray);
    }

    // () Parent State:  Clear Cart + State.cart
    const handleClearCart = (value) => {
      setCartItems(value);
    }

    


   
    


  //  <Route path="/payment-successful"  element={<PaymentSuccessfulPage db_products={props.db_products} shipping={props.shipping}/>} />
  
  
  return (

  <StyledDiv className="App" >
    
     <div style={{ visibility: onCheckout ?  'hidden' : 'visible' }}>
        <NavigationBar db_products={props.db_products} shipping={props.shipping}  />
     </div>


        <Routes>
          <Route path="/" element={ <Home db_products={props.db_products} shipping={props.shipping} /> } />
          <Route path="/products" element={ <AllProducts db_products={props.db_products} shipping={props.shipping} /> } />
          <Route path="/promotions"  element={<Promotions db_products={props.db_products} shipping={props.shipping}/>} />
          <Route path="/aboutus"  element={<AboutUs db_products={props.db_products} shipping={props.shipping}/>} />
          <Route path="/stores"  element={<Stores db_products={props.db_products} shipping={props.shipping}/>} />
          <Route path="/payment-container"  element={<Holder db_products={props.db_products} shipping={props.shipping}/>} />
          <Route path="/payment-success" element={<PaymentSuccessPage db_products={props.db_products} shipping={props.shipping}  client_order_details = {props.client_order_details}/> } /> 
        </Routes>





        <Routes>
            <Route path="/product"   >
              <Route path=":id/:productID/:productName" element={ 
              
              <Product db_products={props.db_products} shipping={props.shipping}

              total={orderSubtotal} 

              addMethod={handleAddCartItems}
              removeMethod={handleRemoveCartItems}
              clearMethod={handleClearCart}

              orderSubtotal={orderSubtotal}
              

              /> }  />
            </Route>
        </Routes>







          <Routes>
            <Route path="/confirmation" >

              <Route path=":orderid" element={
                <OrderDetailsPage 
                  db_products={props.db_products} shipping={props.shipping}
                  total={orderSubtotal}
                />
              } />
              
            </Route>
          </Routes>





          <Routes>
              <Route path="/checkout" >

                <Route path=":orderid" element={
                  <Checkout db_products={props.db_products} shipping={props.shipping} onCheckout={setOnCheckout}/>} />
                            
                  <Route path=":orderid/payment-successful" element={
                      <PaymentSuccessful db_products={props.db_products} shipping={props.shipping} 
                                        client_order_details={props.client_order_details}/> } >
                  </Route>

              </Route>

          </Routes>


    </StyledDiv>


    
  );
}





const mapState_App = (state) => (
  // state = initState
  { state_cart: state.cart,
  }
)

const mapDispatchFunc_App = (dispatch) => ({

    appendItems: (state , item) => {
      dispatch({type: 'APPEND', state: state, payload: item});
    },

    updateItems : (state, item) => {
      dispatch({type: 'UPDATE', state: state, payload: item})
    },

    deductItems : (state, item) => {
      dispatch({type: 'DEDUCT', state: state, payload: item})
    },

    removeItems : (state, item) => {
      dispatch({type: 'REMOVE', state: state, payload: item})
    },

    clearCart : (item) => {
      dispatch({type: 'CLEAR', payload: item})
    },

});


export default connect(mapState_App, mapDispatchFunc_App)(App);
