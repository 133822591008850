
import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import supabase from '../Config';
import StripeContainer from '../comp/StripeContainer';

const Test = (props) => {

    
    // Generate orderID by random
    function randomID(number) { return number < 10 ? '0' + number : number } ;
    var date = new Date();
    var converted = date.getFullYear().toString() + randomID(date.getMonth() +1 ) + randomID(date.getDate()) + randomID(date.getHours() ) + randomID(date.getMinutes() ) ;
    var letter = String.fromCharCode(65 + Math.round(Math.random() * 26));
    var orderNumber = converted + letter + Math.round( Math.random() * 1000 );
    
    const orderid = orderNumber;



    // Styling
    const styles = {
        add_order_container: {marginTop: '200px',},
        button_insert_row_container: {marginTop: '50px', marginBottom: '50px'}
    }


    // useParams
    const { id } = useParams();
    
    var productID = props.db_products[Number(id)-1]["productID"]; 
    var productName = props.db_products[Number(id)-1]["productName"]; 
    var productPrice = props.db_products[Number(id)-1]["productPrice"];
    var productQuantity = props.quantity; 
    var orderTotal = props.total; var orderEmail = props.email; var orderAddress = props.address; 
    var pic = props.db_products[Number(id)-1]["imgURL"];



    // Create state, the fetch
    const [ orders, setOrders ] = useState([]);
    const [ showStripe, setShowStripe ] = useState(false);

    useEffect(() => { getData(); }, [] );

    async function getData() {
        const { data } = await supabase.from("orders").select();
        setOrders(data);
    };


    // array
    const allOrders = orders;


    const handleTest = (e) => {
        const newObject = { itemID: '003', itemName: 'Travertine Tile', itemIMG: 'https://getmy3ds.com/Travertine.jpeg', itemPrice: 25 , itemQuantity: 6 };
    
        // 1. Dispatch Function for reducer
        props.addItems(props.dataCartStatus, newObject);
        
        // 2. Update states of Parent Comp to re-render, by this prop function, finally use useEffect() hook listening to variable(s) updates.
        // props.addMethod('003', 'Travertine Tile', 'https://getmy3ds.com/Travertine.jpeg', 25, 6);
    
    }
    
    
    


    // Insert new row to Supabase
    async function handleConfirm() {

        const { error } = await supabase.from('orders').insert([
            { id: (orders.length + 1), OrderID: orderid, ProductID: productID, ProductName: productName, Price: productPrice, Quantity: productQuantity, OrderTotal: orderTotal, EmailAddress: orderEmail, ShippingAddress: orderAddress }
        ])
        



        if (error) {
            console.error(error);

        } else {
            console.log('Data inserted successfully:', orders);
            setOrders([...orders, { id: orders.length + 1, OrderID: orderid, ProductID: productID, ProductName: productName, Price: productPrice, Quantity: productQuantity, OrderTotal: orderTotal, EmailAddress: orderEmail, ShippingAddress: orderAddress }]);
        }

    };

    


    
    return (
    <div >


        <div style={styles.payment_info_container}>     { showStripe ? <StripeContainer amount={props.total} orderID={orderid} /> : <div></div> }     </div>


        <div style={styles.payment_input_form}>
            <button style={styles.button_confirm_payment} type="submit" value= " " onClick={()=> setShowStripe(true)}> Test Stripe </button>
        </div>


        <div style={styles.add_order_container}>

            <div style={styles.data}>
                
                {allOrders.map((item) => (
                    <p key={item.OrderID}> ID: {item.id} ,  {item.OrderID}</p>
                ))}
            </div>
            

            <div style={styles.button_insert_row_container}>
                <button onClick={handleConfirm} style={styles.button_confirm}> Insert Row </button>
            </div>

        </div>










    </div>






    )
};



const mapState_Test= (state) => {
    return (
        {state_cart: state.cart}
    )
};





const mapDispatchFunc_Test = (dispatch) => ({
    addItems: (state , item) => {
        dispatch({type: 'ADD', state: state, payload: item});
    },
    
    updateItems : (state, item) => {
        dispatch({type: 'UPDATE', state: state, payload: item})
    },
    
    deductItems : (state, item) => {
        dispatch({type: 'DEDUCT', state: state, payload: item})
    },

    removeItems : (state, item) => {
        dispatch({type: 'REMOVE', state: state, payload: item})
    },

});



export default connect(mapState_Test, mapDispatchFunc_Test)(Test);
