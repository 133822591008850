import { React,  useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, NavLink } from "react-router-dom";
import styled, { css } from 'styled-components';
import CartItemContainer from "./comp/CartItemContainer";





const NavigationBar = (props) =>  {
  
  const styles = {
    container: { display: 'flex' },
    row : { display: 'flex'},
    
    link: { color: '#535859', fontWeight: 'bold', fontSize: '14px', textDecoration: 'none', },
    
    nav: { display: 'flex', marginLeft: '2vw', marginRight: '1vw', width: '80vw', },
    a: { display: 'flex', flexDirection: 'column', justifyContent: 'center', marginRight: '3vw', width: '10vw'},
    img: { width: '65%', height: '55%'},
    logoName: { fontSize: '10px', fontWeight: 'bold', scale: '85%', width: '1%'},
    url: { fontSize: '9px', width: '1%'},

    cartContainer: { opacity: '0', transition: 'opacity 300ms ease 0s',  display: 'none'},
    displayNone: { display: 'none' },
    


    cart_button: { width: '45%', height: '65%'},
    display_none: { display: 'none', },

    cart_main_container: { display: 'flex', flexDirection: 'column' },
    cart_container: { display: 'flex', flexDirection: 'column'   },
    cart_items_container: { },

    cart_subtotal_header_container: { display: 'flex', flexDirection: 'column', marginTop: '2vh',  },
    cart_subtotal_description_container: { marginBottom: '10vh', },

    button_clearcart_checkout_container: { display: 'flex', flexDirection: 'column', },
    clear_cart: { height: '35%',} ,
    clear_cart_text: {  paddingTop: '0.1vw', paddingBottom: '0.3vw', },
    
    check_out_container: { } ,
    check_out: { width: '90%',  },
    
  
  };

  

  // Format Date
  function formatDate(number) { return number < 10 ? '0' + number : number } ;
  var date = new Date();
  var dateConverted = date.getFullYear().toString() + formatDate(date.getMonth() +1) + formatDate(date.getDate()) + formatDate(date.getHours() ) + formatDate(date.getMinutes() ) ;
  // Generate orderID by random
  var randomInt1 = Math.round( Math.random() * 1000 );
  var first3Digits = randomInt1 < 100? "0" + randomInt1 : randomInt1;
  
  var letter = String.fromCharCode(65 + Math.round(Math.random() * 25));
  
  var randomInt2 = Math.round( Math.random() * 100 );
  var last2Digits = randomInt2 < 100? "0" + randomInt2 : randomInt2;
  var lastDigits = last2Digits.length == 2 ?  "0" + last2Digits : last2Digits;

  var orderID = String(first3Digits + letter) + "-" + String(lastDigits) + "-" + String(dateConverted);



    //  Updated state
    const [ orderSubtotal, setOrderSubtotal ] = useState(0);
    const [ cartItems, setCartItems ] = useState(props.state_cart);


    //  Initial Subtotal
    var cartInitSubtotal =  0 ;
    for (let i=0; i < props.state_cart.length; i++) {
      cartInitSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
    }


    //  To check local state, once props.state_cart updated
    useEffect(() => { 
      setCartItems(props.state_cart); 

      var subtotal = 0;
      // update subtotal by Redux state
      for (let i=0; i < props.state_cart.length; i++) {
        subtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
      };

      setOrderSubtotal(subtotal);
    } , 
    [props.state_cart]);


    


   

   //  Clear Cart  [ Redux ]
   const handleClearCart = (value) => {
    // -- Dispatch Remove function
    const object = {array: []} ;
    props.clearCart(object);

  }



  




  return (



      <div style={{ backgroundColor: 'transparent', display: 'flex', width: '100vw' }} data-animation="default" className="navigation w-nav" data-easing2="ease" data-easing="ease" data-collapse="medium" data-w-id="4990a16e-0ffe-6b5d-24e6-cb04cd55b9ef" role="banner" data-wf-id="[&quot;6e9029c6-fd4c-41f5-af7d-fb15c9565d1e&quot;,&quot;4990a16e-0ffe-6b5d-24e6-cb04cd55b9ef&quot;]" data-duration="400">
        
        <div href="/" style={styles.a}>
            <img style={styles.img} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuQ3xWPIxeggfpNergwXZgJMnNgX2yHzZ2X2p92bAbQNCXwux22vjARsiANkqlblL-rTI&usqp=CAU" alt="" className="logo-image"/>
            <p id="logoName" style={styles.logoName}>GetMy3Ds</p>
            <Link id="urlsite" style={styles.url} className="App-link" href="https://getmy3ds.com" target="_blank" rel="noopener noreferrer"> getmy3ds.com </Link>
        </div>


        <div className="navigation-items" data-w-id="4990a16e-0ffe-6b5d-24e6-cb04cd55b9f0" data-wf-id="[&quot;6e9029c6-fd4c-41f5-af7d-fb15c9565d1e&quot;,&quot;4990a16e-0ffe-6b5d-24e6-cb04cd55b9f0&quot;]" >
      
          <div className="navigation-wrap" data-w-id="4990a16e-0ffe-6b5d-24e6-cb04cd55b9f3" data-wf-id="[&quot;6e9029c6-fd4c-41f5-af7d-fb15c9565d1e&quot;,&quot;4990a16e-0ffe-6b5d-24e6-cb04cd55b9f3&quot;]" >
            <div style={styles.nav} role="navigation" data-w-id="4990a16e-0ffe-6b5d-24e6-cb04cd55b9f4" data-wf-id="[&quot;6e9029c6-fd4c-41f5-af7d-fb15c9565d1e&quot;,&quot;4990a16e-0ffe-6b5d-24e6-cb04cd55b9f4&quot;]" >
              <Link style={styles.link} className="navigation-item w-nav-link" to="/">Hot Deals</Link>
              <Link style={styles.link} className="navigation-item w-nav-link" to="/products">Products</Link>
              <Link style={styles.link} className="navigation-item w-nav-link" to="/stores">Stores</Link>
              <Link style={styles.link} className="navigation-item w-nav-link" to="/aboutus">Team</Link>
                      
            
            </div>
            
            
            
            <div style={styles.cart_button} className="w-commerce-commercecartwrapper cart" data-node-type="commerce-cart-wrapper" data-open-product="" data-wf-cart-type="rightSidebar" data-wf-page-link-href-prefix="">
                        
                <Link href="#" className="w-commerce-commercecartopenlink button cc-cart w-inline-block" data-node-type="commerce-cart-open-link"  role="button" aria-haspopup="dialog" aria-label="Open cart">
                    <div className="w-inline-block">Cart</div>
                    <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22Number%22%2C%22filter%22%3A%7B%22type%22%3A%22numberPrecision%22%2C%22params%22%3A%5B%220%22%2C%22numberPrecision%22%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItemsCount%22%7D%7D%5D" className="w-commerce-commercecartopenlinkcount cart-quantity"></div>
                </Link>
                      
                <div className="w-commerce-commercecartcontainerwrapper w-commerce-commercecartcontainerwrapper--cartType-rightSidebar" data-node-type="commerce-cart-container-wrapper" style={styles.display_none} >
                          
                          
                          <div data-node-type="commerce-cart-container" role="dialog" className="w-commerce-commercecartcontainer cart-container" id="cartContainer" >
                            
                            
                            <div className="w-commerce-commercecartheader cart-header" name=''>
                                  <h4 className="heading-jumbo-tiny">your cart</h4>
                                  <a href="#" data-node-type="commerce-cart-close-link" className="w-commerce-commercecartcloselink close-button w-inline-block" role="button" aria-label="Close cart">
                                      <img src="https://assets.website-files.com/5c6eefaaeddf9248ac13bc72/5c6ef116eddf9216ae13c295_close-icon.svg" alt="" className="icon"/>
                                  </a>
                            </div>
                            
                            
                            <div className="w-commerce-commercecartformwrapper" name=''>
                              
                              <div className="w-commerce-commercecartemptystate" style={styles.cart_main_container}>
                                
                                <div className="paragraph-light">Selected Item(s): </div>
                                

                                <div style={styles.cart_container}>
                                  
                                  <div style={styles.cart_items_container}>
                                    <CartItemContainer data={props.data} dataCartStatus={cartItems}/>
                                  </div>



                                  <div style={styles.cart_subtotal_header_container} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd973" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd973&quot;]" className="w-commerce-commercecheckoutblockheader block-header" >
                                    <h4 data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd974" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd974&quot;]" className="heading-jumbo-tiny">Cart Subtotal</h4>
                                  </div>
                                  

                                  <div style={styles.cart_subtotal_description_container} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd978" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd978&quot;]" className="w-commerce-commercecheckoutblockcontent block-content" >
                                    
                                      <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fd" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fd&quot;]" className="w-commerce-commercecheckoutordersummaryextraitemslist" >
                                      
                                        <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f9" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f9&quot;]" className="w-commerce-commercecheckoutsummarylineitem">
                                        
                                          <div>
                                            <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd983" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd983&quot;]" className="w-commerce-commercecheckoutlabel">Subtotal</label>
                                          </div>
                                        
                                          <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.subtotal%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fc" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fc&quot;]">HKD$&nbsp;{orderSubtotal}.00&nbsp;
                                          </div>
                                        
                                        </div>
                                        
                                        
                                        
                                        <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fe_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fe_instance-0&quot;]" className="w-commerce-commercecheckoutordersummaryextraitemslistitem">
                                        
                                            <div>
                                              <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd979" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd979&quot;]" className="w-commerce-commercecheckoutlabel">Shipping Fee</label>
                                            </div>
                                          
                                            <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.extraItems%5B%5D.price%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dda00_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dda00_instance-0&quot;]">HKD$&nbsp;{props.shipping.pickup}.00&nbsp;
                                            </div>

                                        </div>

                                      </div>
                                      
                                  </div>


                                  <div style={styles.button_clearcart_checkout_container}>

                                    <button aria-live="" style={styles.clear_cart} onClick={handleClearCart} data-node-type="commerce-cart-error" className="w-commerce-commercecarterrorstate status-message cc-error-message">
                                        <p style={styles.clear_cart_text} className="w-cart-error-msg">CLEAR CART</p>
                                    </button>
                                    
                                    <div>
                                      <Link to={`/checkout/${orderID}`} >
                                        <button style= {styles.check_out}>
                                          <a href="/checkout:orderid" value="Continue to Checkout" className="w-commerce-commercecartcheckoutbutton button">Continue to Checkout</a>
                                        </button>
                                      </Link>
                                    </div>

                                  </div>


                                  
                                </div>
                                
                              </div>
                              
                            </div>
                            

                          </div>
                          
                </div>
                      
            </div>
           
          </div>

        </div>


        

      </div>
      


  

  );

}




const mapState_NavigationBar = (state) => {
    return (
      {state_cart: state.cart}
    )
};


const mapDispatchFunc_NavigationBar = (dispatch) => {
  return {
    addItems: (state , item) => {
        dispatch({type: 'ADD', state: state, payload: item})
    },
    clearCart : (item) => {
      dispatch({type: 'CLEAR', payload: item})
    },
  }
};

export default connect(mapState_NavigationBar, mapDispatchFunc_NavigationBar)(NavigationBar);