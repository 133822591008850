import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import supabase from "./Config";
import rootReducer from './Reducer';


//  XX  Remove
const productList =  [
  { productindex: 0, productid: '001', productname: 'Cement-Tile',  name: 'Cement Tile', src: 'https://getmy3ds.com/CementTile.jpg', price: 10 },
  { productindex: 1, productid: '002', productname: 'Marble-Tile', name: 'Marble Tile', src: 'https://getmy3ds.com/MarbleTile.jpg', price: 25 },
  { productindex: 2, productid: '003', productname: 'Travertine-Tile', name: 'Travertine Tile', src: 'https://getmy3ds.com/TravertineTile.jpg', price: 25 },
  { productindex: 3, productid: '004', productname: 'Porcelain-Tile', name: 'Porcelain Tile', src: 'https://getmy3ds.com/PorcelainTile.jpg', price: 25 },
  { productindex: 4, productid: '005', productname: 'Ceramic-Tile', name: 'Ceramic Tile', src: 'https://getmy3ds.com/CeramicTile.jpg', price: 30 },
];
const shippingFee = { pickup: 0, standard: 30, express: 80, ultimate: 220 };





// From database
const supabaseData = async function getDatabase_Products() {
  const { data: products, error } = await supabase
  .from('products')
  .select()

  if (error) {
    // handle error
    console.error(error);
    return [];
  }
  return products;
}





//  Configure Redux Store
const store = configureStore({reducer : rootReducer})




//  JSX - Render the App inside the root division
supabaseData().then((data) => {

  async function getDatabase_Shipping() {
    const { data: shipping, error } = await supabase
    .from('shipping')
    .select()
  
    if (error) {
      // handle error
      console.error(error);
      return [];
    }
    
    return shipping;
  }

    getDatabase_Shipping().then((shipping_fee) => {
      const all_shipping_fee = shipping_fee[0];

      const root = ReactDOM.createRoot(document.getElementById('root'));

      root.render(

      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <App db_products={data} shipping={all_shipping_fee} />
          </BrowserRouter>
        </Provider>
      </React.StrictMode>

      );

    
    });



})


//  ** Alternative
//  ReactDOM.render(<App />, document.getElementById('root'));



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals






// { cartItemID: 1, cartItemProductID: '001', cartItemProductName: 'Cement Tile', src: 'https://getmy3ds.com/CementTile.jpg', cartItemPrice: 10, cartItemQuantity: 2 },
// { cartItemID: 5, cartItemProductID: '005', cartItemProductName: 'Ceramic Tile', src: 'https://getmy3ds.com/CeramicTile.jpg', cartItemPrice: 25, cartItemQuantity: 2  },
// { cartItemID: 3, cartItemProductID: '003', cartItemProductName: 'Travertine Tile', src: 'https://getmy3ds.com/TravertineTile.jpg', cartItemPrice: 25, cartItemQuantity: 2  } ,
// { cartItemID: 2, cartItemProductID: '002', cartItemProductName: 'Marble Tile', src: 'https://getmy3ds.com/MarbleTile.jpg', cartItemPrice: 25, cartItemQuantity: 2  },
// { cartItemID: 4, cartItemProductID: '004', cartItemProductName: 'Porcelain Tile', src: 'https://getmy3ds.com/PorcelainTile.jpg', cartItemPrice: 30, cartItemQuantity: 2  },

