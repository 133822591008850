
import { Elements , stripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { React, useState, useEffect, } from 'react';
import { PaymentElement, LinkAuthenticationElement, useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { Link } from 'react-router-dom';
import PaymentForm from './PaymentForm';

import PaymentInput from './PaymentInput'
import Holder from '../Holder';


function StripeContainer(props) {
    
    // Initialize Stripe
    const STRIPE_PUBLISHABLE_KEY = "pk_test_51OOjwoJPUBzQwAMiuoZZZjMIrDVpDbLsFWCQx8KgLgErv4ETWWE096rkmrUuEvNjzyjLLWuaOpBzkodEPM7xBa0x00OLGlBn3B";

    const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

    const [ clientSecret, setClientSecret] = useState(props.clientSecret);
    const [ message, setMessage ] = useState("");


    
    // 2. When Checkout page loaded, fetch and get the clientSecret from server's response
    //
    useEffect(() => {
      // Create PaymentIntent as soon as the page loads

      async function fetchPaymentIntent() {
        try {   
          const response = await fetch(
            'https://getmy3ds.azurewebsites.net/api/fetchPaymentIntent?code=Z8t20xK1toozmatryBdCh5c3nn3aeLPPhJQP5EEDXD92AzFuROWsOA%3D%3D',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name: props.orderID,
                items: {
                  amount: (props.shippingFee + props.amount) * 100,
                },
              }),
            }
          );
  
            if (!response.ok) {
              throw new Error('Request failed with status: ' + response.status);
            };

          // destructure response as json()
          const responseData = await response.json();
          const { paymentIntent } = responseData;
            if (!paymentIntent) {
              throw new Error('Payment intent not found in the response data');
            };

          // Set client_secret before insert Credit Card Info
          setClientSecret(paymentIntent.client_secret);
          console.log("paymentIntent: " + paymentIntent + ", paymentIntent.client_secret: " + paymentIntent.client_secret);
          console.log('PaymentIntent created!');
        } catch (err) {
          console.error(err);
        }
      }
  
      fetchPaymentIntent();

    }, []);





    // 3. Pass the clientSecret as options as prop for <Element>

    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0570de',
        colorPrimaryText: '#262626',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',

        // See all possible variables below  
      },

    };

    const options = {
      clientSecret,
      appearance,
    };
  


    //  Stripe alternative:  <PaymentInput>

    //  <PaymentInput db_products={props.db_products} orderID={props.orderID} amount={props.amount}  shippingFee={props.shippingFee} 
    //  client_order_details = {props.client_order_details} />



    return (
        <div>

              <Elements options={options} stripe={stripePromise} clientSecret={clientSecret}>
                  <PaymentForm 
                    db_products={props.db_products}
                    orderID={props.orderID}
                    amount={props.amount}  shippingFee={props.shippingFee} 
                    client_order_details = {props.client_order_details}/>

              </Elements>


        </div>

    )
}


export default StripeContainer;