import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { connect } from 'react-redux';
import { React, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import supabase from '../Config';
import jsPDFInvoiceTemplate from 'jspdf-invoice-template/dist/index.js';
import { OutputType } from 'jspdf-invoice-template-nodejs';
import { decode } from 'base64-arraybuffer';
import PaymentSuccessful from '../PaymentSuccessful';

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#b1c9f5",
			color: "#000000",
			fontWeight: 500,
			fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#303c8a" },
			"::placeholder": { color: "#303c8a", fontSize: '1rem', fontWeight: '400', lineHeight: '1.5' },
		},
		invalid: {
			iconColor: "#fca0e1",
			color: "#ffc7ee"
		}
	}
}

const PaymentForm = ( props ) => {

    //  const orderid = useParams();     ==>  Object type:[object]    { "orderid" : "xxxxxxxxx" } 

    const orderid = props.orderID;
    const navigate = useNavigate();

    //  Use the `stripe` object from the `useStripe` ^Hooks (from Element's stripePromise prop) to access the Stripe API
    const stripe = useStripe();
    const elements = useElements();

    //  Styling
    const styles  = {
        payButton: { marginTop: "30px"},
        inputFields_div: { backgroundColor: 'white'},
        inputFields: { borderColor: '#c1c1c1'}
    }

    //  Format Date
    function formatDate(number) { return number < 10 ? '0' + number : number } ;
    var date = new Date();
    var genInvoiceTime = formatDate(date.getDate()) + "/" + formatDate(date.getMonth() +1) + "/" + date.getFullYear().toString() + " " + formatDate(date.getHours()) + ":" + formatDate(date.getMinutes()) ;



    //  States
    const [ message, setMessage ] = useState("");
    const [ placeOrder, setPlaceOrder ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ orders, setOrders ] = useState([]);
    

    //  useEffect when mount
    useEffect(() => {
        (async () => {
            const { data } = await supabase.from("orders").select();
            setOrders(data);
        })();
    }, []);



    


    




    






    //      1.1    Called By Button  'Place Order'  

    const handlePayment = async (e, amount) => {
        e.preventDefault();

        if (!stripe || !elements) {
            console.log("Failed in stripe.")
            return stripe + elements;
        }

        //  REMOVE ()   Azure Function 1:  httpTrigger1   [ Test ]
        const { testHTTP } = await axios.get('https://getmy3ds.azurewebsites.net/api/httpTrigger1?code=lQXHspDhlRlyFhzf31CXPJn4mhvXmLUfMxmx9okc73J2AzFuKjW_cw==', {
            params : {
                name: props.orderID,
            }
        });
        console.log(testHTTP);



        //              Azure Function 2:   postCreatePayment

        (async () => {

            try {   
                    //  1.  Create a PaymentMethod  -  Stripe Element:  CardElement
                    const { paymentMethod, error } = await stripe.createPaymentMethod({
                        type: "card",
                        card: elements.getElement(CardElement),
                    });
        
                    if (error) {
                        console.log(error.message);
                        return;
                    }


                    //  2.  Fetch PaymentIntent, postCreatePayment Azure Function
                    const response = await fetch('https://getmy3ds.azurewebsites.net/api/postCreatePayment?code=gWy90p-P3IsQ94KubFzJIcjYSZidK05-udN2nNV2CA2rAzFuHNP44A=='
                    , { method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            name: props.orderID,
                            items: {
                                amount: (props.shippingFee + props.amount) * 100,
                                payment_id: paymentMethod.id,
                            },
                        })
                      });

                    if (!response.ok) {
                        throw new Error('Request failed with status: ' + response.status);
                    }
        
                    const responseData = await response.json();
                    const { paymentIntent } = responseData;
        
                    if (!paymentIntent) {
                        throw new Error('Payment intent not found in the response data');
                    }
        
                    console.log('PaymentIntent created! - ' + paymentIntent + ", " + paymentIntent.client_secret);



                    //  3. Confirm Payment by client_secret
                    stripe.confirmCardPayment(paymentIntent.client_secret, {payment_method: paymentMethod.id})

                     .then(function(result) {
                        if(result.paymentIntent) {
                            confirmOrder(props.client_order_details, props.shippingFee);
                            console.log('Payment Completed!');
                        } 
                        
                        else if (result.error) {
                            console.log('Payment Failed, try again please.');
                        }
                     });
                     

                } catch (error) {
                    // Handle any errors that occurred during the request or response
                    console.log(error);
                }

        })();

    };
    
    

    //      1.2    Stripe payment completed,  Inserting Rows to Database
    async function confirmOrder(order_info, shipping_fee) {
        try {
          const insertPromises = [];
          //    Insert to Database
          for (let i = 0; i < props.state_cart.length; i++) {
            const item = props.state_cart[i];
            insertPromises.push(
              supabase.from('orders').insert([
                {
                  id: orders.length + 1 + i,
                  OrderID: orderid,
                  ProductID: item["cartItemProductID"],
                  ProductName: item["cartItemProductName"],
                  Price: item["cartItemPrice"],
                  Quantity: item["cartItemQuantity"],
                  Subtotal: item["cartItemPrice"] * item["cartItemQuantity"],
                  ClientName: order_info.name,
                  ContactNumber: order_info.contact_number,
                  EmailAddress: order_info.email,
                  ShippingAddress: order_info.full_shipping_address,
                  ShippingFee: shipping_fee,
                  BillingName: order_info.billing_name,
                  BillingAddress: order_info.full_billing_address,
                },
              ])
            );
          }
          //    Wait for all the insert operations to complete
          await Promise.all(insertPromises);
      

          const { data, error } = await supabase
            .from('orders')
            .select()
            .eq('OrderID', orderid)


            // data[0]
            const db_match_invoice_info = {
                order_id: data[0]["OrderID"],
                name: data[0]["ClientName"],
                address: data[0]["ShippingAddress"],
                phone: data[0]["ContactNumber"],
                email: data[0]["EmailAddress"],
                otherInfo: { "Billing Address" : data[0]["BillingAddress"] },
            }

            var db_client_order_total = 0 ;
            for (let i=0; i < data.length; i++) {
                db_client_order_total += data[i]["Subtotal"];
            }           

           
            

            //  *********************************************************************************
            //  *****  Build PDF -  By package 'jspdf-invoice-template/dist/index.js'   *********
            //
                // template
                const props_PDF_template = {
                        outputType: 'save',
                        // onJsPDFDocCreation: function(jsPDF) {}, 
                        // returnJsPDFDocObject: true,
                        fileName: orderid + "_invoice_" + ".pdf",
                        orientationLandscape: false,
                        compress: true,
                        logo: {
                            src: "https://i5.walmartimages.com/seo/Garfield-Friend-Odie-Doggy-Customized-Wall-Decal-Custom-Vinyl-Art-Personalized-Name-Baby-Girls-Boys-Kids-Bedroom-Room-Decor-Stickers-Decoration-Size-_128b2759-42bb-4302-b548-dab552fdce51.3b79b5708b5cf1e4439a4ef55e040a23.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF",
                            type: 'JPG', //optional, when src= data:uri (nodejs case)
                            width: 35.33, //aspect ratio = width/height
                            height: 28.66,
                            margin: {
                                top: 0, //negative or positive num, from the current position
                                left: 0 //negative or positive num, from the current position
                            }
                        },
                        stamp: {
                            inAllPages: true, //by default = false, just in the last page
                            src: "https://i5.walmartimages.com/seo/Garfield-Friend-Odie-Doggy-Customized-Wall-Decal-Custom-Vinyl-Art-Personalized-Name-Baby-Girls-Boys-Kids-Bedroom-Room-Decor-Stickers-Decoration-Size-_128b2759-42bb-4302-b548-dab552fdce51.3b79b5708b5cf1e4439a4ef55e040a23.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF",
                            type: 'JPEG', // optional, when src= data:uri (nodejs case)
                            width: 20, //aspect ratio = width/height
                            height: 20,
                            margin: {
                                top: 0, //negative or positive num, from the current position
                                left: 0 //negative or positive num, from the current position
                            }
                        },
                        business: {
                            name: "Beagles' Home",
                            address: "1681, Beagles Garden, Woofs Kingdom",
                            phone: "(+555) 555 55 55 555",
                            email: "email@example.com",
                            email_1: "info@example.al",
                            website: "www.example.al",
                        },
                        contact: {
                            label: "Payment Confirmation #",
                            name: data[0]["ClientName"],
                            address: data[0]["ShippingAddress"],
                            phone: data[0]["ContactNumber"],
                            email: data[0]["EmailAddress"],
                            otherInfo: data[0]["BillingAddress"],
                        },
                        invoice: {
                            label: "Invoice #: ",
                            num: orderid,
                            invDate: "Payment Date: " + data[0]["PaymentSuccessTime"],
                            invGenDate: "Invoice Date: " + genInvoiceTime,
                            headerBorder: false,
                            tableBodyBorder: false,
                            header: [
                                {
                                    title: "#", 
                                    style: { 
                                      width: 10 
                                    } 
                                  },  
                                  {
                                    title: "OrderID", 
                                    style: { 
                                      width: 50 
                                    } 
                                  }, 
                                  { 
                                    title: "ProductID",
                                    style: {
                                      width: 25
                                    } 
                                  }, 
                                  { 
                                    title: "Product",
                                    style: {
                                      width: 50
                                    } 
                                  }, 
                                  { title: "Price"},
                                  { title: "Quantity"},
                                  { title: "Subtotal"}
                                ],
                            table: data.map((item, index)=>([
                                index + 1,
                                item.OrderID,
                                item.ProductID,
                                item.ProductName,
                                item.Price.toFixed(2),
                                item.Quantity,
                                (item.Price * item.Quantity).toFixed(2)
                            ])),
                            additionalRows: [{
                                col1: 'Total:',
                                col2: 'HKD  ',
                                col3: String(db_client_order_total + shipping_fee)+ ".00",
                                style: {
                                    fontSize: 14 //optional, default 12
                                }
                            },
                            {
                                col1: 'Delivery Fee:',
                                col2: 'HKD  ',
                                col3: String(shipping_fee)+ ".00",
                                style: {
                                    fontSize: 10 //optional, default 12
                                }
                            },
                            {
                                col1: 'Subtotal:',
                                col2: 'HKD  ',
                                col3: String(db_client_order_total)+ ".00",
                                style: {
                                    fontSize: 10 //optional, default 12
                                }
                            }],
                            invDescLabel: "Invoice Note",
                            invDesc: "There are many variations of passages of Lorem Ipsum available.",
                        },
                        footer: {
                            text: "The invoice is created on a computer and is valid without the signature and stamp.",
                        },
                        pageEnable: true,
                        pageLabel: "Page ",
                };

                //  Object for 'upload'
                const props_uploadPDF = {
                    ...props_PDF_template,
                    outputType: 'arraybuffer',
                }

                //  Object for 'save'
                const props_savePDF = {
                    ...props_PDF_template,
                    outputType: 'save',
                }


            //  Download & Upload  .pdf
                    try {
                        //  1.  Save by Browser         --     Client-side: 'save' automatically via browser - by default 
                        const pdfPlainTemplate = jsPDFInvoiceTemplate(props_savePDF);


                        //  2.  Upload PDF to storage    --    Decoded to '64Base BufferArray
                            // console.log("props_uploadPDF.outputType: " + props_uploadPDF.outputType);
                        const pdfToUpload = jsPDFInvoiceTemplate(props_uploadPDF);  // Prepare the object, applying the props as parameter
                        var pdfInvoice_base64Data = pdfToUpload.arrayBuffer;

                       
                        const { file, err } = await supabase.storage
                            .from('getmy3ds-invoices')
                            .upload(`invoices/${orderid}_invoice.pdf`, pdfInvoice_base64Data, { cacheControl: '3600', upsert: false, });

                        console.log('File uploaded successfully:');
                                
                                



                        //  3.  Navigate to '/checkout/${ClientOrderID}/payment-successful'
                        //  4.  Send Email to client
                            //      Match and Confirmed by 'OrderID' & 'EmailAddress' -- from database SQL table 'orders' 
                            
                         const { data: retrievedOrder, error: orderMatchError } = await supabase
                            .from('orders')
                            .select('*')
                            .eq('OrderID', db_match_invoice_info.order_id)
                            .eq('EmailAddress', db_match_invoice_info.email)
                                
                            
                        if (retrievedOrder) {
                            const ClientName =  retrievedOrder[0]["ClientName"];
                            const ClientOrderID = retrievedOrder[0]["OrderID"];
                            const ClientEmail = retrievedOrder[0]["EmailAddress"];

                            const emailInfoPayload = {
                                client_name: ClientName,
                                order_id: ClientOrderID, 
                                email_address: ClientEmail,
                                    //    invoice_image: invoiceImage,  // invoice_image_url: invoiceURL,
                            };

                            //  3.  Navigate to './payment-successful' 
                            navigate(`/checkout/${ClientOrderID}/payment-successful`); 


                            //  4.  Email sending
                            sendInvoiceNotification(emailInfoPayload);
                        }
                            
                        //  Upload URL from database  -  Match from image storage 'invoices' -- Get image URL, stored as invoiceURL to be used in email body
                        //  const { data: invoiceImage, error: invoiceImageError } = await supabase.storage
                        //    .from('getmy3ds-invoices')
                        //    .download(`invoices/${orderid}_invoice.pdf`);
                        //  const invoiceURL = invoiceImage ? URL.createObjectURL(invoiceImage) : null ;

                            
                    } catch (error) {
                            
                    }

        } catch (error) {
        console.error('Error submitting order:', error);
        // Add more robust error handling here, such as displaying an error message to the user
        }

    }



    //      1.3     Send Invoice email
    async function sendInvoiceNotification(payload) {

        const client_name = payload.client_name;
        const order_id = payload.order_id;
        const userEmail = payload.email_address;  
         //    const invoice_image = payload.invoice_image;
         //    const invoice_imageUrl = payload.invoice_image_url; 
         
         



        try {
          //    1.    Construct the email message
          const emailSubject = 'Your Order Confirmed - ' + order_id;
          const emailBody = `
          Dear ${client_name},

            Your order and payment has been confirmed. Thank you for your continued support.
            
            Attached is the invoice for your order, see also the payment confirmation at: "{}".

            If you have any questions, please don't hesitate to contact us.

            Best Regards,
            GetMy3Ds
            
        `;


           



         //     2.   =========================    By Mailjet     ==============================
         // 
         //       Invoke 'send_email_message' edge functions created in Supabase

            try {
                const { v4: uuidv4 } = require('uuid');
                const messageObject = { 
                    sender: 'customers-order@getmy3ds.com', 
                    recipient: userEmail, 
                    subject: emailSubject, 
                    text_body: emailBody, 
                    html_body: '', 
                    message_id: uuidv4()
                };

                const { response, error } = await supabase.rpc('send_email_message', {message: messageObject});

                if (response) {
                    console.log("Email Sent by Mailjet successfully -- response: " + response);
                } else {
                    console.log("Sending Failed -- ERROR: " + error);
                }
                
                
                

                
                //  ()  Insert email_queue to Supabase
                try {
                    const { response, error } =  await supabase.from("email_queue").insert({
                        recipient: userEmail, subject: emailSubject, text: emailBody,
                            // attachments: [ {   name: `${orderid}.pdf`,content: invoice_image,encoding: 'base64',type: 'application/pdf'} ] 
                        });

                    if (response) {
                        console.log('upload Supabase - email_queue successfully');
                    }
                    
                } catch (error) {
                        console.error('Error upload email_queue', error);
                }  
                

                
                return { success: true }; 

            } catch (error) {
                console.error('Error sending email:', error);
                return { success: false, error: error.message };
            }

        } catch (error) {
          console.error('Error sending email:', error);
        }
    }







    return (

     <div>

            { 

            <form style={styles.inputFields_div} onSubmit={()=>{}} >


                <fieldset className="Form-group">

                    <div className="FormRow">
                        <CardElement options={CARD_OPTIONS} style={styles.inputFields} />


                        <a href="#" style={{ marginTop: '35px'}} value="Place Order" onClick={handlePayment} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dda05" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dda05&quot;]" data-node-type="commerce-checkout-place-order-button" className="w-commerce-commercecheckoutplaceorderbutton button" data-loading-text="Placing Order...">Place Order</a>
                    </div>

                </fieldset>


            </form>

            
            
            }
        
    



        </div>

    )
}


const mapState_PaymentForm = (state) => ({
  state_cart: state.cart, 
  state_shipping: state.shipping,
})


const mapDispatchFunc_PaymentForm = (dispatch) =>  ({
    addItems: (state , item) => {
        dispatch({type: 'ADD', state: state, payload: item});
    },
})


export default connect(mapState_PaymentForm, mapDispatchFunc_PaymentForm)(PaymentForm);


// <p>Order placed. Your Payment is Successful. </p>

