import axios from 'axios';
import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams, useLocation  } from 'react-router-dom';
import styled, { css } from 'styled-components';
import './App.css';
import StripeContainer from './comp/StripeContainer';
import supabase from './Config';
import { jsPDF } from 'jspdf';
import Holder from './Holder';

const size = {
  mobile: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}
export const mobile = (inner) => css`
  @media (max-width: ${size.mobile}) {
    ${inner};
  }
`;
export const tablet= (inner) => css`
  @media (min-width: ${size.tablet}) {
    ${inner};
  }
`;
export const desktop= (inner) => css`
  @media (min-width: ${size.desktop}) {
    ${inner};
  }
`;
export const laptop= (inner) => css`
  @media (min-width: ${size.laptop}) {
    ${inner};
  }
`;
const StyledDiv = styled.div`
    ${mobile(css`
    width:300px;
      `)};
      
    ${tablet(css`
    width:740px;
    `)};

    ${desktop(css`
    width:1000px;
    `)};
`;


const Checkout = ( props ) => {

    const { orderid } = useParams();
    const styles = {
        main_container: { display: 'flex', flexDirection: 'column'},
        header_checkout: { marginBottom: '20px', color: '#8f8e8e', },
        header_text: { fontSize: '24px', fontWeight: 'bold'},

        radio_input_container : { paddingLeft: '35px', paddingRight: '35px'} ,
        payment_info_container : { backgroundColor: 'transparent',},
    
        applepay_button: { width: '5vw'}
    }

    const [clientSecret, setClientSecret] = useState("");
    useEffect(() => {
      // Create PaymentIntent as soon as the page loads
    }, []);



    const [ shippingFee, setShippingFee ] = useState(0);
    const [ showItem, setShowItem ] = useState(false);

    const [ message, setMessage ] = useState("");
    const [ placeOrder, setPlaceOrder ] = useState(false);
    
    
    const [ showBillingAddress, setShowBillingAddress ] = useState(true);
    const toggleBillingAddress = () => {
        setShowBillingAddress(!showBillingAddress);
    };

    const [ clientDetails, setClientDetails ] = useState(
        { name: '',
          contact_number: '', 
          email: '', 
          address_line1: '',
          address_line2: '',
          address_city_zip_postal: '',
          address_country: '' ,
          full_shipping_address: '',
          
          billing_name: '',
          billing_address_line1: '',
          billing_address_line2: '',
          billing_address_city_zip_postal: '',
          billing_address_country: '' ,

          full_billing_address: '', 
        }
    );

    const handleClientDetails = (event) => {
        console.log("Updated: " + event.target.value);
        console.log("...clientDetails: " + event.target.name + ": " + event.target.value);
      
        // Callback function
        setClientDetails(  
            //  CALLBACK:  RETURNED OBJECT
               (prevState) => {

            // Scenario 1   // Must wait for returned either 'updatedClientDetails' , or 'client_complete_details'
          const updatedClientDetails = { ...prevState, [event.target.name]: event.target.value };
    
            // Scenario 2 & 3 
            if (  event.target.name === 'address_line1' || event.target.name === 'address_line2'   ||
                event.target.name === 'address_city_zip_postal'  || event.target.name === 'address_country') {

                // Define new
                const fullAddress =
                updatedClientDetails.address_line1 + ", " + updatedClientDetails.address_line2 + ", " +
                updatedClientDetails.address_city_zip_postal + ", " + updatedClientDetails.address_country + ", "; 
        
                // Scenario 2   ==> returned object to 'reset' Client Details state
                const client_complete_details = { ...updatedClientDetails, 'full_shipping_address': fullAddress };
                return client_complete_details;
            }

            if (  event.target.name === 'billing_name' || event.target.name === 'billing_address_line1' || event.target.name === 'billing_address_line2' ||
                  event.target.name === 'billing_address_city_zip_postal' || event.target.name === 'billing_address_country') {

                const billingName = updatedClientDetails.billing_name;

                const billingAddress =
                updatedClientDetails.billing_address_line1 + ", " + updatedClientDetails.billing_address_line2 + ", " +
                updatedClientDetails.billing_address_city_zip_postal + ", " + updatedClientDetails.billing_address_country;
        
                // Scenario 3
                const client_complete_details = { ...updatedClientDetails, 'billing_name': billingName, 'full_billing_address': billingAddress };
                return client_complete_details;
            }


          // Scenario 1
          return updatedClientDetails;
        });
        
        console.log("...clientDetails.full_shipping_address : " + clientDetails.full_shipping_address);
    };



    //  useEffect(() => {
        // Check if this is a redirect back from Checkout
    //    const query = new URLSearchParams(window.location.search);

    //    if (query.get("success")) {
    //        setMessage("Order placed! You will receive an email confirmation.");
    //    }

    //    if (query.get("canceled")) {
    //    setMessage(
    //       "Order canceled -- continue to shop around and checkout when you're ready."
    //    );
    //    }
    // }, [placeOrder]);
    //
    //  (X)  'Place Order' button  ==> Redirecting  'Success' or 'Canceled'
    const handlePlaceOrder = async (e, amount) => {
        try {
            const response = await axios.post(`/create-checkout-session`)
            
            .then((response) => {
                console.log(response.data);
                const paymentSuccess = response.data.success;

                if (paymentSuccess) {
                      console.log(" \tPayment Successful. " );
                      setPlaceOrder(true);
                }
                    
                if (!paymentSuccess) {
                      console.log(" \tPayment Failed. " );
                 }

            })

        } catch (err) {
            console.log(err);
        }
    };
    



    //  Selecting shipping options
    const handleShippingFee = (event) => {
        if (event.target.value == 'pickup') {
            setShippingFee(props.shipping.pickup);
        }
        if (event.target.value ==  'standard') {
            setShippingFee(props.shipping.standard); 
        }
        if (event.target.value == 'express') {
            setShippingFee(props.shipping.express) ;
        }
        if (event.target.value ==  'ultimate') {
            setShippingFee(props.shipping.ultimate) ;
        }
    }
    //  Toggling Stripe payment window
    const handlePaymentMethod = (e) => {
        setShowItem(true);
    }

    var cartSubtotal = 0 ;
    for (let i=0; i < props.state_cart.length; i++) {
     cartSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
    }


    const itemsInCart = [...props.state_cart];

    

    return (

    <StyledDiv>

    {  itemsInCart.length > 0 ?
        


(<div style={{position: 'absolute', top:'1px'}} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd967" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd967&quot;]" data-node-type="commerce-checkout-form-container"  data-wf-page-link-href-prefix="" className="w-commerce-commercecheckoutformcontainer full-width-form">
    
        <div data-w-id="756aa30d-a952-1ef6-85c5-824413d54965" data-wf-id="[&quot;756aa30d-a952-1ef6-85c5-824413d54965&quot;]" className="container cc-full-width-form-wrap">
            
            <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd969" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd969&quot;]" className="w-commerce-commercelayoutmain order-wrap" >

                <div style={styles.header_checkout} data-node-type="commerce-cart-quick-checkout-actions" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd96a" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd96a&quot;]">
                    
                    <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9df" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9df&quot;]" className="w-commerce-commercecheckoutsummaryblockheader block-header">
                        <h4  style={styles.header_text} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e0&quot;]" className="heading-jumbo-tiny">Items In Cart</h4>
                    </div>

                </div>
                    
                <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9de" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9de&quot;]" className="w-commerce-commercecheckoutorderitemswrapper">
                    <fieldset data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e2" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e2&quot;]" className="w-commerce-commercecheckoutblockcontent block-content">
                        <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e3" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e3&quot;]" role="list" className="w-commerce-commercecheckoutorderitemslist order-item-list">
                        
                            { 
                                itemsInCart.map( (item, index) => 
                                    <div key={index} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e4_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e4_instance-0&quot;]" role="listitem" className="w-commerce-commercecheckoutorderitem">
                                        <img data-wf-bindings="%5B%7B%22src%22%3A%7B%22type%22%3A%22ImageRef%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.sku.f_main_image_4dr%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e5_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e5_instance-0&quot;]" src={item["src"]} alt="" className="w-commerce-commercecartitemimage" />
                                        <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e6_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e6_instance-0&quot;]" className="w-commerce-commercecheckoutorderitemdescriptionwrapper" >
                                            <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22PlainText%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.product.f_name_%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e7_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e7_instance-0&quot;]" className="w-commerce-commerceboldtextblock product-name-text cc-name-text-cart">{item["cartItemProductName"]}</div>
                                            <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e8_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e8_instance-1&quot;]" className="w-commerce-commercecheckoutorderitemquantitywrapper checkout-quantity-wrap">
                                                <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9e9_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9e9_instance-1&quot;]">Quantity:  </div>
                                                <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22Number%22%2C%22filter%22%3A%7B%22type%22%3A%22numberPrecision%22%2C%22params%22%3A%5B%220%22%2C%22numberPrecision%22%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.count%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9eb_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9eb_instance-&quot;]">{item["cartItemQuantity"]}</div>
                                            </div>
                                            
                                            <ul data-wf-bindings="%5B%7B%22optionSets%22%3A%7B%22type%22%3A%22CommercePropTable%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.product.f_sku_properties_3dr%22%7D%7D%2C%7B%22optionValues%22%3A%7B%22type%22%3A%22CommercePropValues%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.sku.f_sku_values_3dr%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9ec_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9ec_instance-0&quot;]" className="w-commerce-commercecheckoutorderitemoptionlist option-list">
                                                <li data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9ed_instance-0-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9ed_instance-0-0&quot;]">
                                                    <span data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9ee_instance-0-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9ee_instance-0-0&quot;]" data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22PlainText%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.product.f_sku_properties_3dr%5B%5D.name%22%7D%7D%5D">Price</span>
                                                    <span data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9ef_instance-0-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9ef_instance-0-0&quot;]">: </span>
                                                    <span data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f1_instance-0-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f1_instance-0-0&quot;]" data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePropValues%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.product.f_sku_properties_3dr%5B%5D%22%7D%7D%5D">HKD $   {item["cartItemPrice"]}.00</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.rowTotal%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f2_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f2_instance-0&quot;]" className="order-item-price">Subtotal:  HKD $   &nbsp;{item["cartItemQuantity"] * item["cartItemPrice"]}.00&nbsp;</div>
                                    </div>
                            
                            )}

                            
                        </div>
                    </fieldset>
                </div>


                    <form data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd982" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd982&quot;]" style={{ padding: '10px' }} className="w-commerce-commercecheckoutblockcontent block-content">
                        <div> 
                            <div className="w-commerce-commercecheckoutcustomerinfowrapper" style={{ marginBottom: '60px', marginRight: '15px'}}>
                                
                                <div className="w-commerce-commercecheckoutblockheader block-header">
                                    <h4 data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd974" data-wf-id={["992b7cac-1ae1-ef14-d4d9-dd177e5dd974"]} className="card-title">Contact Info</h4>
                                    <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd976" data-wf-id={["992b7cac-1ae1-ef14-d4d9-dd177e5dd976"]} className="paragraph-tiny cc-paragraph-tiny-light" style={{ fontStyle: 'italic', marginTop:'10px' }}>* Required</div>
                                </div>


                                <div className="custom-contact-form-container">
                                    <div>               
                                        <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd983" data-wf-id={["992b7cac-1ae1-ef14-d4d9-dd177e5dd983"]} className="w-commerce-commercecheckoutlabel" style={{ marginTop: '16px', fontWeight: 'bold' }}>Name / Last Name *</label>
                                        <input type="text" name="name" onChange={handleClientDetails} className="w-commerce-commercecheckoutshippingfullname text-field" id="checkout-name" required="" placeholder="Mr./ Ms." />
                                    </div>
                                    
                                    <div>
                                        <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd979" data-wf-id={["992b7cac-1ae1-ef14-d4d9-dd177e5dd979"]} className="w-commerce-commercecheckoutlabel" style={{ marginTop: '16px', fontWeight: 'bold' }}>Contact No. *</label>
                                        <input type="text" name="contact_number" onChange={handleClientDetails} className="w-commerce-commercecheckoutemailinput text-field" id="checkout-phone-number"  placeholder="Contact Number" />
                                    </div>

                                    <div>
                                        <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd979" data-wf-id={["992b7cac-1ae1-ef14-d4d9-dd177e5dd979"]} className="w-commerce-commercecheckoutlabel" style={{ marginTop: '16px', fontWeight: 'bold' }}>Email *</label>
                                        <input type="text" name="email" onChange={handleClientDetails} className="w-commerce-commercecheckoutemailinput text-field" id="checkout-email" placeholder="Email" />
                                    </div>   
                                </div>

                            </div>

                            <div className="w-commerce-commercecheckoutdeliveryaddresswrapper" style={{ marginBottom: '60px', marginRight: '15px'}}>
                                <div className="w-commerce-commercecheckoutblockheader block-header">
                                    <h4 data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd974" data-wf-id={["992b7cac-1ae1-ef14-d4d9-dd177e5dd974"]} className="card-title">Delivery</h4>
                                    <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd976" data-wf-id={["992b7cac-1ae1-ef14-d4d9-dd177e5dd976"]} className="paragraph-tiny cc-paragraph-tiny-light" style={{ fontStyle: 'italic', marginTop:'10px' }}>* Required</div>
                                </div>
                
                                <div>
                                    <label style={{ width: '85%'}} className="w-commerce-commercecheckoutshippingmethoditem shipping-method" >
                                        <input required="" onClick={handleShippingFee} value="pickup" type="radio" name="shipping-method-choice"  />

                                        <div style={{ marginRight: '10px' }} className="w-commerce-commercecheckoutshippingmethoddescriptionblock" >
                                            <div style={{ fontSize: '14px', width: '25vw', }} className="paragraph-light">Pick Up - Same Day</div>
                                        </div>
                                    
                                        <div style={{ marginRight: '7px',  width: '10vw', }} className="order-item-price">&nbsp;Free&nbsp;</div>
                                    </label>
                                
                                
                                    <label style={{ width: '85%'}} className="w-commerce-commercecheckoutshippingmethoditem shipping-method">
                                        <input required="" onClick={handleShippingFee} value = "standard"  type="radio" name="shipping-method-choice"  />
                                        
                                        <div className="w-commerce-commercecheckoutshippingmethoddescriptionblock">
                                            <div style={{ fontSize: '14px' , width: '25vw', }} className="paragraph-light">Standard - 7-8 Days Shipping </div>
                                        </div>
                                        
                                        <div style={{ marginLeft: '7px',  width: '10vw', }} className="order-item-price">HKD &nbsp;{ props.shipping.standard }.00&nbsp;</div>
                                    </label>
                        
                        
                                    <label style={{ width: '85%'}} className="w-commerce-commercecheckoutshippingmethoditem shipping-method">
                                        <input required="" onClick={handleShippingFee} value = "express" type="radio" name="shipping-method-choice"/>
                                        
                                        <div className="w-commerce-commercecheckoutshippingmethoddescriptionblock">
                                            <div style={{ fontSize: '14px', width: '25vw', }} className="paragraph-light"> Express - 4-6 Days Shipping  </div>
                                        </div>
                                        
                                        <div style={{ marginLeft: '7px', width: '10vw', }} data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9a5_instance-1&quot;]" className="order-item-price">HKD &nbsp;{props.shipping.express}.00&nbsp;</div>
                                    </label>
                            
                            
                                    <label style={{ width: '85%'}} className="w-commerce-commercecheckoutshippingmethoditem shipping-method">
                                        <input required="" onClick={handleShippingFee} value = "ultimate" type="radio" name="shipping-method-choice" />

                                        <div className="w-commerce-commercecheckoutshippingmethoddescriptionblock">
                                            <div style={{ fontSize: '14px', width: '25vw', }} className="paragraph-light">Ultimate - 2 Days Shipping  </div>
                                        </div>
                                        
                                        <div style={{ marginLeft: '7px', width: '10vw' }} data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9a5_instance-2&quot;]" className="order-item-price">HKD &nbsp;{props.shipping.ultimate}.00&nbsp;</div>
                                    </label>

                                </div>

                                <label style={{marginTop: '15px'}} htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd986" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd986&quot;]" className="w-commerce-commercecheckoutlabel">Unit & Building</label>
                                <input onChange={handleClientDetails} className="w-commerce-commercecheckoutshippingstreetaddress text-field" name="address_line1" type="text" required="" />

                                <label style={{marginTop: '15px'}} htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd986" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd986&quot;]" className="w-commerce-commercecheckoutlabel">Street No.</label>
                                <input onChange={handleClientDetails} className="w-commerce-commercecheckoutshippingstreetaddressoptional text-field" name="address_line2" type="text" />
                            
                                <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd98a" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd98a&quot;]" className="w-commerce-commercecheckoutrow">
                                    <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd98b" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd98b&quot;]" className="w-commerce-commercecheckoutcolumn">
                                        <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd98c" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd98c&quot;]" className="w-commerce-commercecheckoutlabel">City/ Zip Postal</label>
                                        <input onChange={handleClientDetails} className="w-commerce-commercecheckoutshippingcity text-field" name="address_city_zip_postal" type="text" required="" />
                                    </div>
                                </div>
                            
                                <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd997" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd997&quot;]" className="w-commerce-commercecheckoutlabel">Country/ City </label>
                                
                                <select data-wf-bindings="%5B%7B%22value%22%3A%7B%22type%22%3A%22PlainText%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.shippingAddressCountry%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd999" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd999&quot;]" className="w-commerce-commercecheckoutshippingcountryselector select" name="address_country_code">
                                    <option onChange={handleClientDetails} name="address_country" value="HK">Hong Kong</option>
                                </select>
                            
                                
                                <div data-wf-bindings="%5B%5D" data-wf-conditions="%7B%22condition%22%3A%7B%22fields%22%3A%7B%22requiresShipping%22%3A%7B%22eq%22%3A%22true%22%2C%22type%22%3A%22Bool%22%7D%7D%7D%2C%22dataPath%22%3A%22database.commerceOrder%22%7D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9bc" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9bc&quot;]" className="w-commerce-commercecheckoutbillingaddresstogglewrapper billing-address-toggle-2">
                                    <input id="checkbox" checked={showBillingAddress} onChange={toggleBillingAddress} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9bd" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9bd&quot;]" data-node-type="commerce-checkout-billing-address-toggle-checkbox" className="w-commerce-commercecheckoutbillingaddresstogglecheckbox checkbox-2" type="checkbox"/>
                                    <label htmlFor="checkbox" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9be" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9be&quot;]" className="w-commerce-commercecheckoutbillingaddresstogglelabel checkbox-label-2">Billing address same as shipping</label>
                                </div>

                            </div>
                        </div>
                    </form>


                    { !showBillingAddress && (
                       <div> 
                            <form className="w-commerce-commercecheckoutbillingaddresswrapper">
                                <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9c1" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9c1&quot;]" className="w-commerce-commercecheckoutblockheader block-header">
                                    <h4 data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9c2" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9c2&quot;]" className="card-title">Billing Address</h4>
                                    <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9c4" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9c4&quot;]" className="paragraph-tiny cc-paragraph-tiny-light">* Optional</div>
                                </div>
                                
                                <div className="w-commerce-commercecheckoutblockcontent block-content">
                                        <label style={{marginTop: '15px'}} htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd986" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd986&quot;]" className="w-commerce-commercecheckoutlabel">Client Name</label>
                                        <input onChange={handleClientDetails} className="w-commerce-commercecheckoutshippingstreetaddress text-field" name="billing_name" type="text" required="" />

                                        <label style={{marginTop: '15px'}} htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd986" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd986&quot;]" className="w-commerce-commercecheckoutlabel">Unit & Building</label>
                                        <input onChange={handleClientDetails} className="w-commerce-commercecheckoutshippingstreetaddress text-field" name="billing_address_line1" type="text" required="" />

                                        <label style={{marginTop: '15px'}} htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd986" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd986&quot;]" className="w-commerce-commercecheckoutlabel">Street No.</label>
                                        <input onChange={handleClientDetails} className="w-commerce-commercecheckoutshippingstreetaddressoptional text-field" name="billing_address_line2" type="text" />
                                    
                                        <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd98a" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd98a&quot;]" className="w-commerce-commercecheckoutrow">
                                            <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd98b" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd98b&quot;]" className="w-commerce-commercecheckoutcolumn">
                                                <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd98c" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd98c&quot;]" className="w-commerce-commercecheckoutlabel">City/ Zip Postal</label>
                                                <input onChange={handleClientDetails} className="w-commerce-commercecheckoutshippingcity text-field" name="billing_address_city_zip_postal" type="text" required="" />
                                            </div>
                                        </div>
                                    
                                        <label htmlFor="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd997" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd997&quot;]" className="w-commerce-commercecheckoutlabel">Country/ City </label>
                                        
                                        <select data-wf-bindings="%5B%7B%22value%22%3A%7B%22type%22%3A%22PlainText%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.shippingAddressCountry%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd999" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd999&quot;]" className="w-commerce-commercecheckoutshippingcountryselector select" name="billing_address_country_code">
                                            <option onChange={handleClientDetails} name="billing_address_country" value="HK">Hong Kong</option>
                                        </select>
                                </div>

                            
                            </form>
                        </div>
                    )}
            
            </div>
            


            
            <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f3" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f3&quot;]" className="w-commerce-commercelayoutsidebar order-summary">
                
                <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f4" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f4&quot;]" className="w-commerce-commercecheckoutordersummarywrapper">
                    <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f5" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f5&quot;]" className="w-commerce-commercecheckoutsummaryblockheader block-header">
                        <h4 data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f6" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f6&quot;]" className="heading-jumbo-tiny">Order Summary</h4>
                    </div>

                    <fieldset data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f8" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f8&quot;]" className="w-commerce-commercecheckoutblockcontent block-content">
                        
                        <div style ={{display: 'flex' , justifyContent: 'space-between'}} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f9" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f9&quot;]" className="w-commerce-commercecheckoutsummarylineitem">
                            <label style ={{ textAlign: 'left'}} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fa" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fa&quot;]">Subtotal</label>

                            <div style ={{ display: 'flex', justifyContent: 'space-between'}}>
                                <div> {"HKD "}{"$ "} </div>
                                <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.subtotal%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fc" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fc&quot;]">&nbsp;{cartSubtotal}.00&nbsp;</div>
                            </div>
                        </div>

                        <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fd" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fd&quot;]" className="w-commerce-commercecheckoutordersummaryextraitemslist">
                            <div style ={{display: 'flex', justifyContent: 'space-between'}} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fe_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fe_instance-0&quot;]" className="w-commerce-commercecheckoutordersummaryextraitemslistitem">
                                <label style ={{ textAlign: 'left'}} data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22PlainText%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.extraItems%5B%5D.name%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9ff_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9ff_instance-0&quot;]">Shipping Fee</label>

                                <div style ={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div > {"HKD "}{"$ "} </div>
                                    <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.extraItems%5B%5D.price%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dda00_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dda00_instance-0&quot;]">&nbsp;{shippingFee}.00&nbsp;</div>
                                </div>
                            </div>
                        </div>
                        
                        <div style ={{display: 'flex', justifyContent: 'space-between'}} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dda01" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dda01&quot;]" className="w-commerce-commercecheckoutsummarylineitem">
                            
                            <label style ={{ textAlign: 'left'}} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dda02" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dda02&quot;]">Total</label>
                        
                            <div style ={{ display: 'flex', justifyContent: 'space-between'}}>
                                <div style={{ fontWeight: 'bold'}} > {"HKD "}{"$  "} </div>
                                <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.total%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dda04" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dda04&quot;]" className="w-commerce-commercecheckoutsummarytotal total-count-text">&nbsp;{cartSubtotal + shippingFee}.00&nbsp;</div>
                            </div>

                        </div>

                    </fieldset>
                </div>


                <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9a9" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9a9&quot;]" className="w-commerce-commercecheckoutpaymentinfowrapper">
                    
                    <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9aa" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9aa&quot;]" className="w-commerce-commercecheckoutblockheader block-header">
                        <h4 data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9ab" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9ab&quot;]" className="card-title">Payment Information</h4>
                        <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9ad" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9ad&quot;]" className="paragraph-tiny cc-paragraph-tiny-light">* Required</div>
                    </div>
                    
                    <div>
                        <label style={styles.radio_input_container} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9a0_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9a0_instance-0&quot;]" className="w-commerce-commercecheckoutshippingmethoditem shipping-method">

                            <div>
                                <input required="" onClick={handlePaymentMethod} type="radio" name="shipping-method-choice" style={{ backgroundColor: 'black' }}/>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'horizontal'}}>
                                
                                <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9a2_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9a2_instance-0&quot;]"  className="w-commerce-commercecheckoutshippingmethoddescriptionblock" >  
                                    <div style ={{display: 'flex'}}>
                                        <div className="card-title" style={{fontWeight: 'bold'}}>Credit Cards</div>

                                        <div className="FormFieldInput-Icons" style={{opacity: '1', marginLeft:'15px', width: '80px'}}>
                                            
                                            <div className="CardFormFieldGroupIcon" style ={{display: 'flex'}}> 
                                                <div style={{transform: 'none'}}>
                                                    <span className="FormFieldInput-IconsIcon is-visible">
                                                        <img src="https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg" alt="Visa" className="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                    </span>
                                                </div>

                                                <div style={{transform: 'none'}}>
                                                    <span className="FormFieldInput-IconsIcon is-visible">
                                                        <img src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" alt="MasterCard" className="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                    </span>
                                                </div>

                                                <div style={{transform: 'none'}}>
                                                    <span className="FormFieldInput-IconsIcon is-visible">
                                                        <img src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" alt="American Express" className="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                    </span>
                                                </div>
                                            </div>   
                                            
                                            <div className="CardFormFieldGroupIconOverflow">
                                                <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--visible" role="presentation">
                                                    <span className="FormFieldInput-IconsIcon" role="presentation">
                                                        <img src="https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg" alt="UnionPay" className="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                    </span>
                                                </span>
                                                <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--invisible" role="presentation">
                                                    <span className="FormFieldInput-IconsIcon" role="presentation">
                                                        <img src="https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg" alt="JCB" className="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div> 

                                    <div className="paragraph-light" style={{textAlign: 'left'}}>Mastercards, Visa, AE, UnionPay, JCB</div>
                                </div>


                                <div style ={{display: 'flex'}}>
                                    <div style ={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '30px'}} >{"HKD"}{"$  "}</div>
                                    <div style ={{ fontSize: '14px' }} data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.total%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dda04" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dda04&quot;]" className="w-commerce-commercecheckoutsummarytotal total-count-text">&nbsp;{cartSubtotal + shippingFee}.00&nbsp;</div>
                                </div>
                            </div>

                        </label>
                    </div>


                    <div style={styles.payment_info_container}> 
                    
                        { showItem ?
                            <div>
                                <StripeContainer 
                                db_products={props.db_products}
                                orderID={orderid} amount={cartSubtotal} shippingFee={shippingFee} 
                                client_order_details={clientDetails} options={props.options}  />

                            </div>
                            : 
                            <div></div>
                        }   

                    </div>




                    

                    

                    
                </div>
        
            </div>
            
            
            
            





        </div>

    </div>


    ) : <div></div>

        

        }







        


    </StyledDiv>
    );
}




const mapState_Checkout = (state) => (
    {  state_cart: state.cart,
    }
)


const mapDispatchFunc_Checkout = (dispatch) => ({
    addItems: (state , item) => {
        dispatch({type: 'ADD', state: state, payload: item});
    },
    
    updateItems : (state, item) => {
        dispatch({type: 'UPDATE', state: state, payload: item})
    },
    
    deductItems : (state, item) => {
        dispatch({type: 'DEDUCT', state: state, payload: item})
    },

    removeItems : (item) => {
        dispatch({type: 'REMOVE', payload: item})
    },

    clearCart : (item) => {
        dispatch({type: 'CLEAR', payload: item})
    },

});

export default connect(mapState_Checkout, mapDispatchFunc_Checkout)(Checkout);