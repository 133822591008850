import { React } from 'react';
import { connect } from 'react-redux';
import PerItemContainer from './PerItemContainer';


const CartItemContainer = (props) => {

    var cartInitSubtotal =  0 ;
        for (let i=0; i < props.state_cart.length; i++) {
        cartInitSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
    }


    const styles = {
        main_container: { width: '320px', marginBottom: '10px'},
        sub_container: { marginTop: '10px', marginBottom: '25px'},
        button_clear_cart: { marginRight: '18vw', width: '24vw', height: '45px'},
        button_check_out: {  marginLeft: '1.2vw', width: '25vw'},
    }
    


    return (

    <div style={styles.main_container}>


            { props.state_cart.map( (item, index) => (
            

            <PerItemContainer key={index+1} style={styles.sub_container}
                productID={item["cartItemProductID"]}
                productName={item["cartItemProductName"]}
                productIMG={item["src"]}
                productPrice={item["cartItemPrice"]}
                productQuantity={item["cartItemQuantity"]}
                addMethod={props.addMethod}
                removeMethod={props.removeMethod}
                index={index}
            />
                
            
            )
            
            )  }
            
            
            
    </div>

    )
};



const mapState_CartItemContainer = (state) => (
    // state = initState
    { state_cart: state.cart,
    }
)

const mapDispatchFunc_CartItemContainer = (dispatch) => ({
    addItems: (state , item) => {
        dispatch({type: 'ADD', state: state, payload: item});
    },
    
    updateItems : (state, item) => {
        dispatch({type: 'UPDATE', state: state, payload: item})
    },
    
    deductItems : (state, item) => {
        dispatch({type: 'DEDUCT', state: state, payload: item})
    },

    removeItems : (item) => {
        dispatch({type: 'REMOVE', payload: item})
    },

    clearCart : ( item) => {
        dispatch({type: 'CLEAR', payload: item})
    },

});



export default connect(mapState_CartItemContainer, mapDispatchFunc_CartItemContainer )(CartItemContainer);


