


import { React } from 'react';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';


const size = {
  mobile: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}







const OrderDetailsContainer = ( props ) => {

  const styles = {
    container: {display: 'flex', flexDirection: 'column', },
    container2: {display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '95vw', marginLeft: '2vw', padding: '20px', },
    header: { marginLeft: '35px', },

    heading:  { paddingTop: '25px', paddingBottom: '60px', width: '95vw', marginLeft: '', fontSize: '24px'},
    pic:  { height: '180px',  width: '180px',  marginLeft: '40vw'},
    label : {  marginRight: '50px', marginBottom: '2vh', height: '3vh', width: '25vw', display: 'flex', justifyContent: 'flex-start'},
    state : {  marginBottom: '3vh',  height: '2vh', width: '30vw',  display: 'flex', flexDirection: 'column', placeItems: 'start'} ,
    labelContainer : { display: 'flex', flexDirection: 'column', placeItems: 'start', fontSize: '14px', fontWeight: 'bold',  width: '20vw', marginLeft:'8vw' },
    orderDetailContainer: { display: 'flex', flexDirection: 'column', placeItems: 'start', fontSize: '14px', fontWeight: 'bold', paddingLeft: '45px', marginRight:' 30vw', width: '25vw'  },


  }


    const { orderid } = useParams();

    
    var productID = props.data[props.index]["productid"];
    var productName = props.data[props.index]["name"];
    var productPrice = props.data[props.index]["price"];
    var productQuantity = props.quantity;
    var orderTotal = props.total;
    var orderEmail = props.email;
    var orderAddress = props.address;
    var pic = props.data[props.index]["src"];







return (


    <div style={styles.container}>
      
        
        <h2 style={styles.heading}> Your Order No. {orderid} </h2>



        <div style={styles.container2}>

          <img src={pic} style={styles.pic}/>
          

          <div style={styles.labelContainer}>
            <lable style={styles.label}> Product ID: </lable>
            <lable style={styles.label}> Product Name: </lable>
            <lable style={styles.label}> Price: </lable>
            <lable style={styles.label}> Quantity: </lable>
            <lable style={styles.label}> Order Total:  </lable>
            <lable style={styles.label}> Email Address: </lable>
            <lable style={styles.label}> Shipping Address: </lable>
          </div>


          <div style={styles.orderDetailContainer}>
            <p style={styles.state}>   {props.data[props.index]["productid"]} </p>
            <p style={styles.state}>  {props.data[props.index]["name"]} </p>
            <p style={styles.state}>  HKD $  {props.price} </p>
            <p style={styles.state}>  {props.quantity} </p>
            <p style={styles.state}>  HKD {props.total}  </p>
            <p style={styles.state}>   {props.email}  </p>
            <p style={styles.state}>   {props.address}  </p>

          </div>


        </div>








    </div>
    
    );
};

export default OrderDetailsContainer;