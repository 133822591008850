import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import CartItemContainer from './comp/CartItemContainer';
import NavigationBar from './NavigationBar';
import Test from './unused/Test';

const size = {
    mobile: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}
export const mobile = (inner) => css`
    @media (max-width: ${size.mobile}) {
      ${inner};
    }
`;
export const tablet= (inner) => css`
    @media (min-width: ${size.tablet}) {
      ${inner};
    }
`;
export const desktop= (inner) => css`
    @media (min-width: ${size.desktop}) {
      ${inner};
    }
`;
export const laptop= (inner) => css`
    @media (min-width: ${size.laptop}) {
      ${inner};
    }
`;
const StyledDiv = styled.div`
      ${mobile(css`
      width:300px;
        `)};
        
      ${tablet(css`
      width:740px;
      `)};
  
      ${desktop(css`
      width:1000px;
      `)};
`;


const Product = ( props ) => {
    const { id, productID, productName } = useParams();

    //  Initial Subtotal
    var cartInitSubtotal =  0 ;
    for (let i=0; i < props.state_cart.length; i++) {
      cartInitSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
    }

    // Database storing products detail
    const ID = props.db_products[Number(id)-1]["productID"];
    const Name = props.db_products[Number(id)-1]["productName"];
    const imgURL = props.db_products[Number(id)-1]["imgURL"];
    const price = props.db_products[Number(id)-1]["productPrice"];


    // Format Date
    function formatDate(number) { return number < 10 ? '0' + number : number } ;
    var date = new Date();
    var dateConverted = date.getFullYear().toString() + formatDate(date.getMonth() +1) + formatDate(date.getDate()) + formatDate(date.getHours() ) + formatDate(date.getMinutes() ) ;
    // Generate orderID by random
    var randomInt1 = Math.round( Math.random() * 1000 );
    var first3Digits = randomInt1 < 100? "0" + randomInt1 : randomInt1;
    var letter = String.fromCharCode(65 + Math.round(Math.random() * 25));
    var randomInt2 = Math.round( Math.random() * 100 );
    var last2Digits = randomInt2 < 100? "0" + randomInt2 : randomInt2;

    var orderID = String(first3Digits + letter) + "-" + String(last2Digits) + "_" + String(dateConverted);


    // Styling
    const styles = {
        main_container: { display: 'flex', flexDirection: 'column' , width: '95vw'},
        navBar_cartButton_container: { display: 'flex', width: '100vw' },
        display_none: { display: 'none', },

        info_container: { display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '80vw', marginLeft: '5vw', marginRight: '5vw', },
        product_IMG_container : {  height: '35vh', width: '35vw', },
        product_IMG: { marginTop: '20vh', marginRight: '15vw', height: '15vw', width: '15vw',  },

        description_container: { display: 'flex', flexDirection: 'row',  marginLeft: '4vw', },
        product_description : {  marginTop: '3vh', marginLeft: '3vw'},
        
        form_container: { marginBottom: '50px'},
        quantity_container : { height: '35px', display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '30px', },
        quantity_controller: { height: '35px', display: 'flex', marginTop: '15px',  marginBottom: '30px', },
        button_add: { height: '35px', width: '35px' , padding: '8px',  },
        button_minus: { height: '35px', width: '35px' , padding: '8px' },
        button_addToCart_container: { display: 'flex', marginLeft: '4vw' , height: '35px',  },
        button_addToCart: {  color: 'white', backgroundColor: 'black', height: '50px', width: '153px',  },
        quantity : { height: '35px', marginLeft: '35px', marginRight: '35px', marginTop: '8px', fontSize: '18px' },
        amount: { marginLeft: '55px', marginTop: '35px', fontSize: '22px' },
        
        label_color : { marginBottom: '30px', marginTop: '30px', marginRight: '30px'},
        label_phone_number : { marginBottom: '30px', marginTop: '30px', marginRight: '30px'},
        label_email: { marginBottom: '30px', marginTop: '30px', marginRight: '30px'},
        label_address : { marginBottom: '30px', marginTop: '30px', marginRight: '30px'},
        label_billing_address : { marginBottom: '30px', marginTop: '30px', marginRight: '30px'},


        input_phone_number: { display: 'flex', marginLeft: '30px' },
        input_email_container: { display: 'flex', marginLeft: '30px' },
        input_shipping_address_container: { display: 'flex',  marginLeft: '30px' },
        input_billing_address_container: { display: 'flex', marginLeft: '30px' },

        input_phone_number: { padding: '10px', marginLeft: '110px', marginRight: '10px', marginBottom: '50px', height: '90px', width: '300px'},
        input_email: { padding: '10px', marginLeft: '110px', marginRight: '10px', marginBottom: '50px', height: '90px', width: '300px'},
        input_shipping_address: { padding: '10px', marginLeft: '110px', marginRight: '10px', marginBottom: '50px', height: '90px', width: '300px'},
        input_billing_address: { padding: '10px', marginLeft: '110px', marginRight: '10px', marginBottom: '50px', height: '20px', width: '20px'},
        
        button_buy_now: { marginLeft: '15px', height: '50px', width: '140px', marginTop: '10px', fontSize:'16px', fontWeight: 'bold', backgroundColor: '#e1d2c9', color: 'grey'},


        cart_button: {  marginTop: '60px',  },
        cart_subheader : { },
        cart_main_container: { display: 'flex', flexDirection: 'column',   },

        cart_container: { display: 'flex', flexDirection: 'column', width: '300px',  },
        cart_items_container: { },

        cart_subtotal_header_container: { display: 'flex', flexDirection: 'column', marginTop: '2vh',  },
        cart_subtotal_description_container: { marginBottom: '10vh', },


        button_clearcart_checkout_container: {display: 'flex', flexDirection: 'column', },
        clear_cart: { height: '35%',},
        clear_cart_text: {  paddingTop: '0.1vw', paddingBottom: '0.3vw', },
    
        check_out_container: { } ,
        check_out: { width: '90%',  },

        shipping_option_container: { display: 'flex', width: '120%', marginLeft: '0px'},
        shipping_option_div: { marginRight: '8px', width: '105%'}
        
    }
    
    useEffect( () => { 
        //  Initial Subtotal
        var cartSubtotal =  0 ;
        for (let i=0; i < props.state_cart.length; i++) {
            cartSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
        }
        setOrderSubtotal(cartSubtotal) ;

        }, [props.state_cart] 
    );


    //   ()  States
    const [ orderSubtotal, setOrderSubtotal ] = useState(cartInitSubtotal);
    const [ total, setTotal ] = useState(0);
    const [ option, setOption ] = useState(null); 

    //       States
    //  ** make use of quantity as 'dispatch object' value
    const [ quantities, setQuantities ] = useState(0);
   
    


    



    //   Increment Button & Decrement Button
    const handleIncrementDecrement = (event) => {
        handleQuantityChange(event);
        handleTotalChange(event, price);
    };

        //  **  Set Quantity 
    const handleQuantityChange = (event) => {
        if (event.target.id == 'add') {
            setQuantities(quantities + 1);

        } else if (quantities > 0 && event.target.id == 'minus') {
            setQuantities(quantities - 1);
        }
    };

        //    ()  Set Total 
    const handleTotalChange = (event, value) => {
        if (event.target.id == 'add') {
            setTotal(total + value);

        } else if (event.target.id == 'minus') {
            setTotal(total - value);
        }
    };


    //   Set product preference choice
    const handleOptionChange = (event) => {
        setOption(event.target.value);
    };





    
    
    //  =====   Convert ====
    const quantitiesNum = Number(quantities);


    //  ======  Dispatch Redux Function ==========
    const handleAppend = (e) => {
        //     Button 'Add to Cart'  [ Redux ]
        //      Dispatch through Redux function to reducer  [ Redux ]
        const newObject = {
            itemID: ID,
            itemName: Name,
            itemIMG: imgURL,
            itemPrice: price,
            itemQuantity: quantitiesNum
        } ;
        props.addNewItem(props.state_cart, newObject)
    }




    //  =========== ()  Button 'Buy Now'  
    const proceedPayment = (event) => {
        // navigate
        // Navigate( /`${oredID}`);
    }

    



    return (

    <div style={styles.main_container} >

        
        <div className="section cc-product-detail" style={styles.info_container} >
            

            <div style={styles.description_container}>
                
                <div style={styles.product_IMG_container}>
                    <img src={imgURL} style={styles.product_IMG}/>
                </div>
                
                
                <div className="product-details-wrap" style={styles.product_description}>

                    <div className="product-detail-main-details">
                        <h1 className="product-detail-name"> {productName} </h1>
                        <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D" className="product-detail-price">HKD $ &nbsp;{price}.00&nbsp;</div>
                        <div className="paragraph-light">Description here</div>
                    </div>
                    
                    <div className="divider cc-dark-divider"></div>
                    
                    <div className="product-informations">
                        
                        <ul role="list" className="w-list-unstyled">

                            <li className="list-item">
                                <div className="paragraph-light">Product ID: </div>
                                <div className="product-detail-measurement">
                                    <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_weight_%22%2C%22to%22%3A%22innerHTML%22%7D%5D"></div>
                                    <div className="product-detail-unit-text">#{productID}</div>
                                </div>
                                
                            </li>

                            <li className="list-item">
                                <div className="paragraph-light">Product Name: </div>
                                <div className="product-detail-measurement">
                                    <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_weight_%22%2C%22to%22%3A%22innerHTML%22%7D%5D"></div>
                                    <div className="product-detail-unit-text">{productName}</div>
                                </div>
                            </li>
                            
                            <li className="list-item">
                                <div className="paragraph-light">Dimensions</div>
                                <div className="product-detail-measurement">
                                    <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_length_%22%2C%22to%22%3A%22innerHTML%22%7D%5D">2.2</div>
                                    <div className="product-detail-unit-text cc-middle-text">in x</div>
                                    <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_width_%22%2C%22to%22%3A%22innerHTML%22%7D%5D">12</div>
                                    <div className="product-detail-unit-text cc-middle-text">in x</div>
                                    <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_height_%22%2C%22to%22%3A%22innerHTML%22%7D%5D">4.5</div>
                                    <div className="product-detail-unit-text">in</div>
                                </div>
                            </li>
                            
                            <li className="list-item">
                                <div className="paragraph-light">Weight</div>
                                <div className="product-detail-measurement">
                                    <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_weight_%22%2C%22to%22%3A%22innerHTML%22%7D%5D">35</div>
                                    <div className="product-detail-unit-text">kg</div>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                    
                    
                    <div className="divider"></div>
                    
                    
                    
                    <div className="add-to-cart">

                        <form data-node-type="commerce-add-to-cart-form" data-commerce-sku-id="5c6f4191c66aa8efed87ed09" data-loading-text="Adding to cart..." data-commerce-product-id="5c6f4191c66aa8b3eb87ecf4" className="w-commerce-commerceaddtocartform"  style={styles.form_container}>
                            
                            <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_sku_values_3dr%22%2C%22to%22%3A%22optionValues%22%7D%5D" data-commerce-product-sku-values="%7B%22f9a7001ff087b782c3ad5088da606865%22%3A%22f007d5f4ac885116d844614a5b84733d%22%7D" data-node-type="commerce-add-to-cart-option-list" data-commerce-product-id="5c6f4191c66aa8b3eb87ecf4" data-preselect-default-variant="false" role="group">
                                
                                <div role="group">
                                    <label data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22PlainText%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22name%22%7D%7D%5D" style={styles.label_color}>Colour</label>
                                    
                                    <select id="option-set-f9a7001ff087b782c3ad5088da606865" data-node-type="commerce-add-to-cart-option-select" data-commerce-option-set-id="f9a7001ff087b782c3ad5088da606865" className="w-select"  >
                                        <option value=" " onChange={handleOptionChange} >Select Colour</option>
                                        <option value="f007d5f4ac885116d844614a5b84733d" onChange={handleOptionChange} > White </option>
                                        <option value="7b12b3d7e64b4858da471d5b3e8ee97e" onChange={handleOptionChange} > Grey </option>
                                        <option value="5d926d68b8d7767aa3636f4c84d20653" onChange={handleOptionChange} > Black </option>
                                    </select>
                                    
                                </div>
                            </div>
                            
                        </form>

                        
                        <label htmlFor="quantity-5b57c5055bc678f0fcfd8773bbeda279" className="cc-quantity-field-label"> Quantity </label>
                        
                        
                        <div style={styles.quantity_container}>
                            <div style={styles.quantity_controller}>
                                <button style={styles.button_add} id="minus" onClick={handleIncrementDecrement}> - </button>
                                <p style={styles.quantity}> {quantities} </p>
                                <button style={styles.button_minus} id="add" onClick={handleIncrementDecrement}> + </button>
                            </div>

                            <div style={styles.button_addToCart_container} > 
                                <button style={styles.button_addToCart} type="submit" data-node-type="commerce-add-to-cart-button" 
                                    data-loading-text="Adding to cart..."  value="Add to Cart" aria-busy="false" aria-haspopup="dialog" 
                                    className="w-commerce-commerceaddtocartbutton button cc-add-to-cart-btn" onClick={handleAppend}>
                                    Add To Cart
                                </button>
                                
                                <Link to={`/confirmation/${orderID}`}  >
                                    <button style={styles.button_buy_now} onClick={proceedPayment}>Buy Now</button>
                                </Link>
                            </div>
                        </div>





                    
                        

                   
                    </div>
                </div>
            
            </div>
            
            
            


            
        


        </div>
    



    </div>


    )


}


const mapState_Product = (state) => {
    return ({
        state_cart: state.cart,
    })
};


const mapDispatchFunc_Product = (dispatch) => {
    
    return {
        
        addNewItem: (state, item) => {
            dispatch({type: 'ADD', state: state, payload: item})
        },
        updateItems : (state, item) => {
            dispatch({type: 'UPDATE', state: state, payload: item})
        },
        
        deductItems : (state, item) => {
            dispatch({type: 'DEDUCT', state: state, payload: item})
        },

        removeItems : (state, item) => {
            dispatch({type: 'REMOVE', state: state, payload: item})
        },
        clearCart : (item) => {
            dispatch({type: 'CLEAR',  payload: item})
        },
    }
};

export default connect(mapState_Product, mapDispatchFunc_Product)(Product);