import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Routes, } from 'react-router-dom';
import styled, { css } from 'styled-components';
import supabase from './Config';
import CartItemContainer from './comp/CartItemContainer';
import NavigationBar from './NavigationBar';
import PerSearchItemContainer from './comp/PerSearchItemContainer';

const size = {
    mobile: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
  }

  export const mobile = (inner) => css`
    @media (max-width: ${size.mobile}) {
      ${inner};
    }
  `;
  export const tablet= (inner) => css`
    @media (min-width: ${size.tablet}) {
      ${inner};
    }
  `;
  export const desktop= (inner) => css`
    @media (min-width: ${size.desktop}) {
      ${inner};
    }
  `;
  export const laptop= (inner) => css`
    @media (min-width: ${size.laptop}) {
      ${inner};
    }
  `;
  
  const StyledDiv = styled.div`
      ${mobile(css`
      width:300px;
        `)};
        
      ${tablet(css`
      width:740px;
      `)};
  
      ${desktop(css`
      width:1000px;
      `)};
  `;


const AllProducts = ( props ) => {

  var styles = {
        main_container: {  display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100vw',  },
        header: {  },

        navBar_cartButton_container: { display: 'flex', flexDirection: 'row', },
        
        nav_bar_container: {  width: '95%', marginBottom: '20px' },
        cart_button_container: { display: 'flex', flexDirection: 'row',   },
        
        cart_button: {  marginTop: '60px',  marginRight: '90px'},
        display_none: { display: 'none', },
        
        cart_main_container: { display: 'flex', flexDirection: 'column'},
        cart_container: { display: 'flex', flexDirection: 'column', width: '320px',   },
        cart_items_container: { },
        
        cart_subtotal_header_container: { display: 'flex', flexDirection: 'column', marginTop: '2vh',  },
        cart_subtotal_description_container: { marginBottom: '10vh', },
        
        button_clearcart_checkout_container: { display: 'flex', flexDirection: 'column', },
        clear_cart: { height: '35%',} ,
        clear_cart_text: {  paddingTop: '0.1vw', paddingBottom: '0.3vw', },
        
        check_out_container: { } ,
        check_out: { width: '90%',  },



        products: { backgroundColor: '#e1d2c9', marginBottom: '45px',  width: '100vw'},

        product_reels: { marginTop: '80px'},
        search: { backgroundColor: '#e1d2c9', paddingBottom: '60px'},

        result_main_container: { display: 'flex', flexDirection: 'row', marginTop: '40px', marginLeft: '50px' },
        result_container: {  paddingLeft: '40px' }

    }

  // Randon ID
  function formatDate(number) { return number < 10 ? '0' + number : number } ;
  var date = new Date();
  var converted = date.getFullYear().toString() + formatDate(date.getMonth() +1 ) + formatDate(date.getDate()) + formatDate(date.getHours() ) + formatDate(date.getMinutes() ) ;

  var letter = String.fromCharCode(65 + Math.round(Math.random() * 26));
  var lastDigits = Math.round( Math.random() * 1000 );
  var last3Digits =  lastDigits < 100? "0" + lastDigits : lastDigits;
  var orderNumber = converted + letter + last3Digits;
  

  //  Initial Subtotal
  var cartInitSubtotal =  0 ;
  for (let i=0; i < props.state_cart.length; i++) {
    cartInitSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
  }


    //  All initial states by useState() hook
    const [ shipping, setShipping ] = useState(0);
    const [ cartItems, setCartItems ] = useState(props.state_cart);


    //  Updated state
    const [ orderSubtotal, setOrderSubtotal ] = useState(cartInitSubtotal);

    //  Set products data
    const [ allProducts, setAllProduct ] = useState([]);
    const [ searchText, setSearchText] = useState('');
    const [ searchResults, setSearchResults ] = useState([]);


    // To check local state, once props.state_cart updated
    useEffect(() => {
      getData();
      setCartItems(props.state_cart);
    } , [props.state_cart]);



    async function getData() {
      const { data,  error } = await supabase.from("products").select();
      if (error) {
        console.error(error);
    }
      setAllProduct(data);
    };



    // ** async Search
    async function handleSearch() {
      
      console.log("Clicked, " + typeof searchText + ", " +  searchText);
      console.log("allProducts : " + typeof allProducts + ", " +  allProducts);

      // Serach Supabase  .like

      try {
          const { data: products, error } = await supabase
          .from('products')
          .select()
          .like('productName', `%${searchText}%`)

        if (error) {
          // 
        } else {
          setSearchResults(products);
          console.log(" \t .like()  " + products);

          for (let i=0; i < products.length ; i++) {
            console.log(" \t products[i]  " + products[i]['productName']);
          }
          
        }
        
      } catch (error) {
        // Handle any other errors
      }

    };





    return (

      <StyledDiv style={styles.main_container} >
        

        <h3> All products </h3>



        <div style={styles.product_reels}>
          

         <div>

          <input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)}/>
          
          <button onClick={handleSearch}>Search</button>

            {/* Display the search results */}

          <div style={styles.result_main_container}>

            { searchResults.map((product) => (

            <div key={product.id} style={styles.result_container}>
            
              <PerSearchItemContainer

              index={product.id}
              productID={product.productID} productName={product.productName}
              productPrice={product.productPrice} productIMG={product.imgURL} />

            </div>

            ))
            
            }
          
          </div>


         </div>


        </div>



      </StyledDiv>

    )


};




const mapState_AllProduct = (state) => (
  // state = initState
  { state_cart: state.cart,
  }
)

const mapDispatchFunc_AllProduct = (dispatch) => ({

    appendItems: (state , item) => {
      dispatch({type: 'APPEND', state: state, payload: item});
    },

    updateItems : (state, item) => {
      dispatch({type: 'UPDATE', state: state, payload: item})
    },

    deductItems : (state, item) => {
      dispatch({type: 'DEDUCT', state: state, payload: item})
    },

    removeItems : (state, item) => {
      dispatch({type: 'REMOVE', state: state, payload: item})
    },

    clearCart : (item) => {
      dispatch({type: 'CLEAR', payload: item})
    },

});


export default connect(mapState_AllProduct, mapDispatchFunc_AllProduct)(AllProducts);








// ======= Test Insert  'products' table

   //   const { error } = await supabase.from("products").insert([
   //     { id: (allProducts.length +1), productID: (allProducts.length +1), productName: `${searchText}`, price: 10}
   //   ])
      
      
   //   if (error ) {
   //     console.error(error);
      
      
   //   } else {
   //     console.log('Test successfully:');
      
   //   }




// ====  Search by SQL query via http

   //   try {
   //     const response = await fetch('https://getmy3ds.azurewebsites.net/api/postSearchProduct?code=l68J7KoukeuoOVG0GlZSmmPH1weCA2lUnP_KK1CKNaWfAzFujRH-rg==', {
   //       method: 'POST',
   //       headers: {
   //         'Content-Type': 'application/json',
   //      },
   //       body: JSON.stringify({ searchText }),
   //     });
    
   //     if (!response.ok) {
   //       throw new Error('Failed to fetch search results');
   //     }
    
   //     const data = await response.json();
   //     // Handle the search results returned from Azure Functions
   //     console.log(data);
        // Perform actions with the search results
        // ...
   //   } catch (error) {
        // Handle any errors that occur during the API request
   //    console.error(error);
   //   }