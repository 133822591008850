
import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import supabase from '../Config';


const PerSearchItemContainer = ( props ) => {

    // const shippingFee = { pickup: 0, standard: 20, express: 40, ultimate: 80 };

    const styles = {
        main_container: {  display: 'flex', flexDirection: 'column' , justifyContent: 'space-evenly', marginTop: '50px', marginBottom: '50px' },
        div1strow: { display: 'flex', flexDirection: 'row', marginLeft: '45px', marginTop: '10px' },
        labelProductName: { marginTop: '25px'},
        row_quantity_container: { display: 'flex', flexDirection: 'row', marginTop: '10px', width:' 320px', },
        row_quantity: { display: 'flex', flexDirection: 'row' , justifyContent: 'center', width: '300px'},
        quantities: { } ,
        div3rdrow: { display: 'flex', flexDirection: 'row',  width:' 320px' },
        priceLabel: { marginLeft: '1vw', marginRight: '30px', paddingTop: '6px' , marginBottom: '13px',},
        price: { marginRight: '30px' , paddingTop: '6px'},
        addToCart : { marginTop: '0%', height: '50%', width: '60%', fontWeight: 'bold', color:'black' },
        remove: { marginRight: '30px', height: '95%', fontWeight: 'bold', color: 'grey', scale: '90%', },
        img: { width: '70px', height: '70px', marginLeft: '30px',  marginRight: '30px', marginBottom: '20px'},
        button: { height: '24px', width: '25px' , padding: '2px', marginLeft: '20px',  marginRight: '20px', marginBottom: '20px'  },


        cart_subtotal_header_container: { display: 'flex', flexDirection: 'column', marginTop: '2vh',  },
        cart_subtotal_description_container: { marginBottom: '10vh', },

    }

    const [ cartItems, setCartItems] = useState(props.state_cart);
    const [ productQuantity, setProductQuantity ] = useState(0);
    const [ productID, setProductID ] = useState('');
    const [ productName, setProductName ] = useState('');
    const [ productPrice, setProductPrice ] = useState('');
    const [ productIMG, setProductIMG ] = useState('');


    useEffect(() => { setCartItems(props.state_cart);
    } , [props.state_cart]);


    //
    const handleQuantityChange = (e) => {

        if (e.target.id == 'add') {
            // setProductSelected
            setProductName(e.target.value);

            // simply and directly receive
            setProductQuantity(productQuantity + 1);

        } else if (e.target.id == 'minus') {
            // setProductSelected
            setProductName(e.target.value);

            // simply and directly receive
            setProductQuantity(productQuantity - 1);

        }
    };



    //  X X  CASE 'UPDATE' ; Update cartItems
    const handleIncrementDecrement = (event) => {
        // add
        if (event.target.id == 'add') {
            const newObject = {
                itemID: props.state_cart[Number(props.index)]["cartItemProductID"],
                itemName: props.state_cart[Number(props.index)]["cartItemProductName"],
                itemIMG: props.state_cart[Number(props.index)]["src"],
                itemPrice: props.state_cart[Number(props.index)]["cartItemPrice"],
                itemQuantity: productQuantity
            } ;
                
            props.updateItems(props.state_cart, newObject);

        }
        
        // minus
        else if (event.target.id == 'minus') {
            const newObject2 = {
                itemID: props.state_cart[Number(props.index)]["cartItemProductID"],
                itemName: props.state_cart[Number(props.index)]["cartItemProductName"],
                itemIMG: props.state_cart[Number(props.index)]["src"],
                itemPrice: props.state_cart[Number(props.index)]["cartItemPrice"],
                itemQuantity: -1
            } ;
            props.deductItems(props.state_cart, newObject2);
        }
    };


    // Case 'REMOVE'
    const handleRemove = (event) => {

        // 1. Set new state in Parent Component
        props.removeMethod(Number(props.index));

        // 2. Dispatch Remove function
        const object = {
            itemID: props.state_cart[Number(props.index)]["cartItemProductID"],
            itemName: props.state_cart[Number(props.index)]["cartItemProductName"],
            itemIMG: props.state_cart[Number(props.index)]["src"],
            itemPrice: props.state_cart[Number(props.index)]["cartItemPrice"],
            itemQuantity: props.state_cart[Number(props.index)]["cartItemQuantity"],
            itemIndex: props.index
        } ;

        props.removeItems(object)


    }


    // Case 'APPEND'
    const handleAddToCart = async (e) => {
        console.log(" \t - add " + " productID: " + e.target.value);
        // search supabase by props.index + props.productName
        try {
            const { data: product, error } = await supabase
            .from('products')
            .select()
            .eq('productID', e.target.value)
            
            if (error) {
                // Handle the error
                console.log(error);

            } else {
                const appendProductID = product[0]["productID"];
                const appendProductName = product[0]["productName"];
                const appendProductPrice = product[0]["productPrice"];
                const appendProductIMG = product[0]["imgURL"];
                
                const newObject = {
                    itemID: appendProductID ,
                    itemName: appendProductName,
                    itemIMG: appendProductIMG,
                    itemPrice: appendProductPrice,
                    itemQuantity: productQuantity
                } ;


                console.log(" NEW OBJ: " + newObject["itemID"] + ", " +
                newObject["itemName"] + ", " +
                newObject["itemIMG"] + ", " +
                newObject["itemPrice"] + ", " +
                newObject["itemQuantity"] + ", "
                );

                props.addNewItem(props.state_cart, newObject);

            }


        } catch (error) {
          // Handle any other errors
        console.log(" Try failed: " + error);
        }

    }




    const data = props.state_cart || {}; // Set a default empty object if props.data is undefined
    const arrayAllItems = Object.values(data);



    return (


    <div style={styles.main_container}>
            <div>
                <div style={styles.div1strow}>
                    <div style={styles.labelProductName}>{ 'Item: '}  {props.productName} </div>
                    <img src={props.productIMG} style={styles.img} />
                </div>

                <div style={styles.row_quantity_container}>
                    <div style={styles.row_quantity}>
                        <div style={styles.quantities}> {'Quantity: '} </div>

                        <button style={styles.button} id="minus" name={props.index} value={props.productName} onClick={handleQuantityChange}> - </button>

                        {productQuantity}

                        <button style={styles.button} id="add" name={props.index} value={props.productName} onClick={handleQuantityChange}> + </button>

                        <button className="label cc-cart-remove-link" style={styles.addToCart}
                        
                            value={props.index} name={props.productName} onClick={handleAddToCart}>Add to Cart
                        
                        </button>

                    </div>
                </div>

                <div style={styles.div3rdrow}>

                    <div style={styles.priceLabel}> {'  Price: '}    </div>

                    <div style={styles.price}>
                        HKD $  {props.productPrice}
                    </div>
                    
                    <button className="label cc-cart-remove-link" style={styles.remove} onClick={handleRemove}>Remove</button>



                </div>
            </div>

    </div>
    );
}




const mapState_PerSearchItemContainer = (state) => (
    // state = initState
    { state_cart: state.cart,
    
    
    }
)


const mapDispatchFunc_PerSearchItemContainer  = (dispatch) => ({
    appendItems: (state , item) => {
        dispatch({type: 'APPEND', state: state, payload: item});
    },
    addNewItem: (state, item) => {
        dispatch({type: 'ADD', state: state, payload: item})
    },
    
    updateItems : (state, item) => {
        dispatch({type: 'UPDATE', state: state, payload: item})
    },
    
    deductItems : (state, item) => {
        dispatch({type: 'DEDUCT', state: state, payload: item})
    },

    removeItems : (item) => {
        dispatch({type: 'REMOVE', payload: item})
    },

    clearCart : (item) => {
        dispatch({type: 'CLEAR', payload: item})
    },

});

export default connect(mapState_PerSearchItemContainer, mapDispatchFunc_PerSearchItemContainer)(PerSearchItemContainer);


