

import { createClient } from '@supabase/supabase-js';


// Use a custom domain as the supabase URL
// const supabase = createClient('https://my-custom-domain.com', 'public-anon-key')


const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;


const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase;