import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';
import styled, { css } from 'styled-components';

import CartItemContainer from './comp/CartItemContainer';
import NavigationBar from './NavigationBar';
import ProductList from './comp/ProductList';

const size = {
  mobile: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}
export const mobile = (inner) => css`
  @media (max-width: ${size.mobile}) {
    ${inner};
  }
`;
export const tablet= (inner) => css`
  @media (min-width: ${size.tablet}) {
    ${inner};
  }
`;
export const desktop= (inner) => css`
  @media (min-width: ${size.desktop}) {
    ${inner};
  }
`;
export const laptop= (inner) => css`
  @media (min-width: ${size.laptop}) {
    ${inner};
  }
`;
const StyledDiv = styled.div`
    ${mobile(css`
    width:300px;
      `)};
      
    ${tablet(css`
    width:740px;
    `)};

    ${desktop(css`
    width:1000px;
    `)};
`;




const Home = ( props ) =>  {

  var styles = {
    main_container: { display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100vw', },

    navBar_cartButton_container: { display: 'flex', flexDirection: 'row',  },
    
    nav_bar_container: { width: '95%', marginBottom: '20px' },
    cart_button_container: {  },

    cart_button: {  marginTop: '60px',  marginRight: '90px'},
    display_none: { display: 'none', },

    cart_main_container: { display: 'flex', flexDirection: 'column'},
    cart_container: { display: 'flex', flexDirection: 'column', width: '320px',   },
    cart_items_container: { },

    cart_subtotal_header_container: { display: 'flex', flexDirection: 'column', marginTop: '2vh',  },
    cart_subtotal_description_container: { marginBottom: '10vh', },

    button_clearcart_checkout_container: { display: 'flex', flexDirection: 'column', },
    clear_cart: { height: '35%',} ,
    clear_cart_text: {  paddingTop: '0.1vw', paddingBottom: '0.3vw', },
    
    check_out_container: { } ,
    check_out: { width: '90%',  },

    product_reels : { marginBottom: '45px', width: '100vw'},

  }
  
    //  Initial Subtotal
    var cartInitSubtotal =  0 ;
    for (let i=0; i < props.state_cart.length; i++) {
      cartInitSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
    }

    //  All initial states 
    const [ shipping, setShipping ] = useState(0);
    const [ cartItems, setCartItems ] = useState(props.state_cart);

    //  Updated state
    const [ orderSubtotal, setOrderSubtotal ] = useState(0);

    //  To check local state, once props.state_cart updated
    useEffect(() => { setCartItems(props.state_cart); } , [props.state_cart]);

    
    //  Clear Cart  [ Redux ]
    const handleClearCart = (value) => {
      const object = {array: [ ]} ;
      props.clearCart(object);
    }
    
 

  // ======================
  // ======================
  
  


  
    
  return (

  <StyledDiv className="Home" style={styles.main_container}>
    
          

        <div style={styles.product_reels}>
          <ProductList db_products={props.db_products} clearMethod={handleClearCart}/>
        </div>




  </StyledDiv>
  


  );
}





const mapState_Home = (state) => (
  // state = initState
  { state_cart: state.cart, 
  }
)

const mapDispatchFunc_Home = (dispatch) => ({


    updateItems : (state, item) => {
      dispatch({type: 'UPDATE', state: state, payload: item})
    },

    deductItems : (state, item) => {
      dispatch({type: 'DEDUCT', state: state, payload: item})
    },

    removeItems : (state, item) => {
      dispatch({type: 'REMOVE', state: state, payload: item})
    },

    clearCart : (item) => {
      dispatch({type: 'CLEAR', payload: item})
    },

});


export default connect(mapState_Home, mapDispatchFunc_Home)(Home);
