import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { React, useEffect, useState } from 'react';
import supabase from './Config';
import { connect } from 'react-redux';
import { jsPDF } from 'jspdf';
import jsPDFInvoiceTemplate from 'jspdf-invoice-template/dist/index.js';
import { OutputType } from 'jspdf-invoice-template-nodejs';
import { decode } from 'base64-arraybuffer';
import { Link , useParams, useLocation, useNavigate } from 'react-router-dom';

//  checkout/819X-072_202406031412/payment-successful    const orderid = "819X-072_202406031412";
    // ()  from props
    //  const client_name = props.client_order_info.name;
    //  const client_contact_number = props.client_order_info.contact_number; 
    //  const client_email = props.client_order_info.email; 
    //  const client_full_shipping_address = props.client_order_info.full_shipping_address; 
    //  const client_billing_address= props.client_order_info.billing_address; 

// Loop rows from data
        // order.forEach((row) => {
        //    const productID = row.ProductID.toString();
        //    const productName = row.ProductName;
        //    const productPrice = row.Price.toString();
        //    const productQuantity = row.Quantity.toString();
        //    const productSubtotal = row.Subtotal.toString();
//});

const PaymentSuccessful = (props) => {

    const { orderid } = useParams();

    const [ data, setData ] = useState([]);
    const [ order, setOrder ] = useState([]);
    const [ order_total, setOrderTotal ] = useState(true);
    const [ order_shipping_fee, setShippingFee] = useState(0);
    const [ loading, setLoading ] = useState(true);

    

    useEffect(() => {

        (async () => {
            if (orderid) {
                try {
                    const { data: clientOrder, error } = await supabase
                        .from('orders')
                        .select()
                        .eq('OrderID', orderid)
                    
                    if (clientOrder && clientOrder.length > 0) {
                        // Set orders
                        setOrder(clientOrder);
                        
                        // Set Shipping
                        setShippingFee(clientOrder[0]["ShippingFee"]);

                        // Set Order_total
                        var db_client_order_total = 0 ;
                        for (let i = 0; i < clientOrder.length; i++) {
                            db_client_order_total += clientOrder[i]["Subtotal"];
                        };

                        // Update Order_total
                        setOrderTotal(db_client_order_total);   

                    } else {
                        setOrder([]);
                    }
                } catch (error) {
                console.error(error);
                } finally {
                setLoading(false);
                }

            } else {
            console.log("\=\= useParams() " + orderid)
            }
        })();
    
        // redirectPaymentSuccessful(orderid);
    }, [orderid, order_total]);


    //  Format Date
    function formatDate(number) { return number < 10 ? '0' + number : number } ;
    var date = new Date();
    var genInvoiceTime = formatDate(date.getDate()) + "/" + formatDate(date.getMonth() +1) + "/" + date.getFullYear().toString() + " " + formatDate(date.getHours()) + ":" + formatDate(date.getMinutes()) ;

    // ()
    async function redirectPaymentSuccessful(id) {
        try {
            const { orderData, error } = await supabase
                .from('orders')
                .select()
                .eq('OrderID', orderid)

                // setData once after fetching
                setData(orderData);

                // Calc subtotal
                var db_client_order_total = 0 ;
                for (let i=0; i < order.length; i++) {
                    db_client_order_total += order[i]["Subtotal"];
                } 

                            
                // Loop rows from data
                data.forEach((row) => {
                    const productID = row.ProductID.toString();
                    const productName = row.ProductName;
                    const productPrice = row.Price.toString();
                    const productQuantity = row.Quantity.toString();
                    const productSubtotal = row.Subtotal.toString();

                });

            } catch (error){

            };
    }

    
    async function downloadInvoice() {
        try {
           const { data, error } = await supabase
            .from('orders')
            .select()
            .eq('OrderID', orderid)


            //  Calculate Subtotal
            var db_client_order_total = 0 ;
            for (let i=0; i < data.length; i++) {
                db_client_order_total += data[i]["Subtotal"];
            }
             

            //  *********************************************************************************
            //  ***************  By package 'jspdf-invoice-template/dist/index.js' **************
            
            const props_savePDF = {
                outputType: 'save',
                    // onJsPDFDocCreation: function(jsPDF) {}, 
                    // returnJsPDFDocObject: true,
                fileName:  orderid + "_order_" + ".pdf",
                orientationLandscape: false,
                compress: true,
                logo: {
                    src: "https://i5.walmartimages.com/seo/Garfield-Friend-Odie-Doggy-Customized-Wall-Decal-Custom-Vinyl-Art-Personalized-Name-Baby-Girls-Boys-Kids-Bedroom-Room-Decor-Stickers-Decoration-Size-_128b2759-42bb-4302-b548-dab552fdce51.3b79b5708b5cf1e4439a4ef55e040a23.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF",
                    type: 'JPG', //optional, when src= data:uri (nodejs case)
                    width: 35.33, //aspect ratio = width/height
                    height: 28.66,
                    margin: {
                        top: 0, //negative or positive num, from the current position
                        left: 0 //negative or positive num, from the current position
                    }
                },
                stamp: {
                    inAllPages: true, //by default = false, just in the last page
                    src: "https://i5.walmartimages.com/seo/Garfield-Friend-Odie-Doggy-Customized-Wall-Decal-Custom-Vinyl-Art-Personalized-Name-Baby-Girls-Boys-Kids-Bedroom-Room-Decor-Stickers-Decoration-Size-_128b2759-42bb-4302-b548-dab552fdce51.3b79b5708b5cf1e4439a4ef55e040a23.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF",
                    type: 'JPEG', // optional, when src= data:uri (nodejs case)
                    width: 20, //aspect ratio = width/height
                    height: 20,
                    margin: {
                        top: 0, //negative or positive num, from the current position
                        left: 0 //negative or positive num, from the current position
                    }
                },
                business: {
                    name: "Beagles' Home",
                    address: "1681, Beagles Garden, Woofs Kingdom",
                    phone: "(+555) 555 55 55 555",
                    email: "email@example.com",
                    email_1: "info@example.al",
                    website: "www.example.al",
                },
                contact: {
                    label: "Payment Confirmation #",
                    name: data[0]["ClientName"],
                    address: data[0]["ShippingAddress"],
                    phone: data[0]["ContactNumber"],
                    email: data[0]["EmailAddress"],
                    otherInfo: data[0]["BillingAddress"],
                },
                invoice: {
                    label: "Invoice #: ",
                    num: orderid,
                    invDate: "Payment Date: " + data[0]["PaymentSuccessTime"],
                    invGenDate: "Invoice Date: " + genInvoiceTime,
                    headerBorder: false,
                    tableBodyBorder: false,
                    header: [
                        {
                            title: "#", 
                            style: { 
                              width: 10 
                            } 
                          },  
                          {
                            title: "OrderID", 
                            style: { 
                              width: 50 
                            } 
                          }, 
                          { 
                            title: "ProductID",
                            style: {
                              width: 25
                            } 
                          }, 
                          { 
                            title: "Product",
                            style: {
                              width: 50
                            } 
                          }, 
                          { title: "Price"},
                          { title: "Quantity"},
                          { title: "Subtotal"}
                        ],
                    table: data.map((item, index)=>([
                        index + 1,
                        item.OrderID,
                        item.ProductID,
                        item.ProductName,
                        item.Price.toFixed(2),
                        item.Quantity,
                        (item.Price * item.Quantity).toFixed(2)
                    ])),
                    additionalRows: [{
                        col1: 'Total:',
                        col2: 'HKD  ' ,
                        col3: String(db_client_order_total + data[0]["ShippingFee"])+ ".00",
                        style: {
                            fontSize: 14 //optional, default 12
                        }
                    },
                    {
                        col1: 'Delivery Fee:',
                        col2: 'HKD  ',
                        col3: String(data[0]["ShippingFee"])+ ".00",
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    },
                    {
                        col1: 'Subtotal:',
                        col2: 'HKD  ',
                        col3: String(db_client_order_total)+ ".00",
                        style: {
                            fontSize: 10 //optional, default 12
                        }
                    }],
                    invDescLabel: "Invoice Note",
                    invDesc: "There are many variations of passages of Lorem Ipsum available.",
                },
                footer: {
                    text: "The invoice is created on a computer and is valid without the signature and stamp.",
                },
                pageEnable: true,
                pageLabel: "Page ",
            };

            //  Default function to save
            const pdfToSave = jsPDFInvoiceTemplate(props_savePDF);

        } catch (error) {
          console.error('Error download formatted PDF:', error);
          // Add more robust error handling here, such as displaying an error message to the user
        }
    }


    return (


        <div>
           <div><h4>Order placed! You will receive an email confirmation.</h4></div> 

            <div className="main-container">
                <div className="email-container">
                   <div className="email-header">
                        <div className="email-header__content">
                            <div className="email-header__logo">
                                <img style={ {width: '180px' , marginBottom: '30px'} } src="https://btw-cdn.com/assets/email/logo-text-black.png" alt=""/>
                            </div>
                            <img src="img/notebook.png" alt="" />

                            <h1 className="email-header__content__title" style={ {color:'#334ac0'}}>
                                Payment Confirmed!
                            </h1>

                            <h3>Order Confirmation  # {orderid} </h3>

                            <p>
                                Your payment has been confirmed! <br />
                                The order confirmation will be sent via email shortly. <br />
                                Thank you for your continued support! <br />
                                Happy Shopping!
                            </p>

                            <div className="btn btn-success">
                                <a href="#">
                                    <button id="download-invoice-btn" onClick={downloadInvoice}>Download Invoice</button>
                                </a>
                            </div>

                            <div className="line"></div>
                            
                            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                                <img src="https://btw-cdn.com/assets/dist/img/icons/3d-lonceng.png" style={{width: '110px', height: '90px'}} />
                                <p style={{fontSize: '12px', color: '#2b2a35' }}>
                                    If you need assistance, we'll be happy to assist you 
                                    <a href="#" style={{color: "orange"}}> Contact Sales Support </a>
                                    <button className="btn btn-success">
                                        <a href="#" style={ {color: '#334ac0', marginLeft: '15px'} }>
                                            Receive Order Confirmation via Email
                                        </a>
                                    </button>
                                </p>
                            </div>
                            
                            <h3 style={{fontSize: '16px', marginTop: '35px' }}>Items in Your Order</h3>

                            <div style={{padding: '10px', backgroundColour: '#F6F6F6', borderRadius: '5px' }}>
                                    <div>
                                        <table style={{backgroundColor: '', }}>
                                            <thead style={{display: 'flex', flexDirection: "horizontal", justifyContent: 'space-evenly'}}>
                                                <div style={{borderBottom: "2px solid #e5e5e5", width: '250px', marginRight:'25px', fontSize: '16px'}}>Items</div>
                                                <div style={{borderBottom: "2px solid #e5e5e5", marginRight: '60px', fontSize: '16px'}}>Quantity</div>
                                                <div style={{borderBottom: "2px solid #e5e5e5", marginRight: '60px', fontSize: '16px'}}>Subtotal</div>
                                            </thead>
                                            <tbody>
                                                {order.length > 0 ? (
                                                    order.map((row, index) => ( 
                                                    <tr key={index} style={{ display: 'flex'}}>
                                                        <td colSpan="2" style={{ borderBottom: "1px solid #e5e5e5", marginRight: '45px', width: '250px', padding: '6px', fontSize: "14px", fontWeight: 'bold', backgroundColor: ''}}>
                                                            <p style={{}}>{row.ProductName}</p>
                                                        </td>
                                                        <td style={{ borderBottom: '2px solid #e5e5e5', marginRight: '80px',  padding: '6px',  fontSize: '12px', fontWeight: 'bold', color: '#000', backgroundColor: '' }}>
                                                            <p style={{}}>{row.Quantity}</p>
                                                        </td>
                                                        <td style={{ borderBottom: '2px solid #e5e5e5', padding: '6px',  fontSize: '12px', fontWeight: 'bold', color: '#000', backgroundColor: ''}}>
                                                            <p style={{}}>HKD {row.Price}.00</p>
                                                        </td>
                                                    </tr>
                                                    ))
                                                ) : (
                                                        <tr>
                                                            <td colSpan={4} style={{ textAlign: 'center', padding: '20px' }}>
                                                                No orders found.
                                                            </td>
                                                        </tr>
                                                )}
                                                
                                                <tr>
                                                    <td style={{backgroundColor: ''}}></td>
                                                    <td style={{width:"150px", fontSize: '14px', padding: '10px', textAlign: 'left', color: '#9F9D9D' }}>Subtotal </td>
                                                    <td style={{width:"150px", padding: '10px', textAlign: 'left', fontSize: '12px', fontWeight: 'bold', color: "#000" }}>HKD ${order_total}.00</td>
                                                </tr>  
                                                
                                                <tr style={{height:"30px"}}>
                                                    <td style={{backgroundColor: ''}}></td>
                                                    <td style={{width:"150px" , fontSize: "14px", color: "#9F9D9D"}}>Delivery Fee</td>
                                                    <td style={{width:"150px", padding:'6px', textAlign:'center', fontSize:'12px', fontWeight: 'bold', color:"#000"}}>HKD ${order_shipping_fee}.00</td>
                                                </tr>

                                                <tr>
                                                    <td style={{backgroundColor: ''}}></td>
                                                    <td style={{width:"150px", fontSize: '14px', padding: '10px', textAlign: 'left', color: '#9F9D9D' }}>Total </td>
                                                    <td style={{width:"150px", padding: '10px', textAlign: 'left', fontSize: '12px', fontWeight: 'bold', color: "#000" }}>HKD ${order_total + order_shipping_fee}.00</td>
                                                </tr> 

                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                        
                        <h3 style={{ display: 'flex', justifyContent: 'center', fontSize: "16px", }}>Customer & Sales Support</h3>

                        <div style={{display: 'flex', flexDirection: "horizontal", justifyContent: 'center', paddingRight: "60px" , marginTop: '15px'}}>
                            
                                <table style={{ margin:'10px', }} >
                                    <tbody>
                                        <tr>
                                            <td style={{fontSize: '12px', paddingBottom:'10px', fontWeight: 'bold'}}>Sales Support</td>
                                            <td style={{fontSize: '12px', fontWeight: 'bold',  textAlign: 'right'}}>{}</td>
                                        </tr>
                                        <tr>
                                            <td>Benjamin Chaplain</td>
                                            <td style={{fontSize: "12px" ,  textAlign: "right"}}>{}</td>
                                        </tr>
                                        <tr>
                                            <td>sales_support@getmy3ds.com</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table style={{ margin:'10px', }}>
                                    <tbody>
                                        <tr>
                                            <td style={{fontSize: '12px', paddingBottom:'10px', fontWeight: 'bold'}}>Payment Methods</td>
                                            <td style={{fontSize: '12px', fontWeight: 'bold', textAlign: 'right'}}>{}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontSize: "12px" ,  textAlign: "center"}}>Credit Cards</td>
                                            <td>{}</td>
                                            <td style={{fontSize: "12px" ,  textAlign: "center"}}>{}</td>
                                        </tr>
                                        <tr>
                                            <td style={{fontSize: "12px" ,  textAlign: "center"}}>Bank Transfer</td>
                                        </tr>
                                    </tbody>
                                </table>

                        </div>

                        </div>
                   </div>

                    <div className="email-footer" style={{ }}>
                        <div className="section" style={{display: 'flex', justifyContent: 'center', marginTop: '25px'}}>
                            <table className="email-footer__menu">
                                <tbody>
                                    <tr className="text-center">
                                        <td style={{width: "50%"}}>
                                            <a href="mailto:tanya@smartbtw.com" target="_blank" style={{ textDecoration:'none', color:"#676666"}}>GetMy3Ds</a>
                                        </td>
                                        <td style={{width: "50%", marginLeft: '20px'}} className="last-child">
                                            <a href="https://wa.me/6281999113000" target="_blank" style={ {textDecoration:'none', color:"#676666"}}>, HK</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="email-footer__body">
                            <p style={{textAlign: "center", color: "#676666"}}>
                                This email notification is from <br />
                                GetMy3Ds
                            </p>
                            <p style={{ textAlign: 'center', color: '#676666', marginBottom: "15px"}}>
                                PT. Bina Taruna Wiratama <br />
                                Smart BTW - Sanur Kaja, Denpasar, Bali, 80228
                            </p>
                            <img src="https://btw-cdn.com/assets/email/logo-text-black.png" alt="" style={ {display:'block', margin: '0 auto', width: '100px' }}/>
                            <p style={{textAlign: "center",  color: "#676666", margin: "10px 0 2px 0"}}>
                                Download our app GetMy3DS Mobile
                            </p>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'center', marginLeft: '20%', marginRight: '20%', marginTop: "15px", marginBottom: "25px", }}>
                            <a href="https://play.google.com/store/apps/details?id=com.bimbelbtw.skd" target="blank">
                                <img style={{width: "100px"}} src="https://btw-cdn.com/assets/email/googleplay.png" alt=""/> 
                            </a>
                            <a href="https://apps.apple.com/id/app/smart-btw/id1541829332?l=id#?platform=iphone" target="blank">
                                <img style={{width: "100px"}} src="https://btw-cdn.com/assets/email/appstore.png" alt=""/> 
                            </a>
                        </div>

                        <div style={{border: "1px solid #e5e5e5", textAlign: "center", boxSizing: 'border-box', borderRadius: "5px"}}>
                        
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{border: "1px solid #e5e5e5"}}>
                                            <a href="https://www.facebook.com/profile.php?id=100059723581712" target="blank">
                                                <img style={{width: "20px"}} src="https://btw-cdn.com/assets/email/facebook.png" alt=""/>  
                                            </a>
                                        </td>
                                        <td style={{border: "1px solid #e5e5e5"}}>
                                            <a href="https://www.instagram.com/bimbelbtw_sekdin/" target="blank">
                                                <img style={{width: "20px"}} src="https://btw-cdn.com/assets/email/instagram.png" alt=""/>  
                                            </a>
                                        </td>
                                        <td style={{border: "1px solid #e5e5e5"}}>
                                            <a href="https://www.youtube.com/channel/UC-LccIlkxr1-Qt3pDSEuKew" target="blank">
                                                <img style={{width: "20px"}} src="https://btw-cdn.com/assets/email/youtube.png" alt=""/>  
                                            </a>
                                        </td>
                                        <td style={{border: "1px solid #e5e5e5"}}>
                                            <a href="https://btw.co.id/" target="blank">
                                                <img style={{width: "20px"}} src="https://btw-cdn.com/assets/email/internet.png" alt=""/> 
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

            </div>

        </div>

    )



};


export default PaymentSuccessful;




