
import { React } from 'react';
import { connect } from 'react-redux';


const PerItemContainer = ( props ) => {


    const styles = {
        main_container: {  display: 'flex', flexDirection: 'column' , justifyContent: 'space-evenly', marginTop: '50px', marginBottom: '50px' },
        div1strow: { display: 'flex', flexDirection: 'row', marginLeft: '45px', marginTop: '10px' },
        labelProductName: { marginTop: '25px'},
        row_quantity_container: { display: 'flex', flexDirection: 'row', marginTop: '10px', width:' 320px', },
        row_quantity: { display: 'flex', flexDirection: 'row' , justifyContent: 'center', width: '300px'},
        quantities: { } ,
        div3rdrow: { display: 'flex', flexDirection: 'row',  width:' 320px' },
        price: { marginLeft: '1vw', marginRight: '30px', paddingTop: '6px' , marginBottom: '13px',},
        subtotal: { marginRight: '30px' , paddingTop: '6px'},
        remove: { marginRight: '30px', height: '95%', fontWeight: 'bold', color:'black', scale: '90%'},
        img: {width: '70px', height: '70px', marginLeft: '30px',  marginRight: '30px', marginBottom: '20px'},
        button: { height: '24px', width: '25px' , padding: '2px', marginLeft: '20px',  marginRight: '20px', marginBottom: '20px'  },


        cart_subtotal_header_container: { display: 'flex', flexDirection: 'column', marginTop: '2vh',  },
        cart_subtotal_description_container: { marginBottom: '10vh', },

    }



    // **********   CASE 'UPDATE' ; Update Redux state management   ***********

    const handleIncrementDecrement = (event) => {
        // add
        if (event.target.id == 'add') {
            const newObject = {
                itemID: props.state_cart[Number(props.index)]["cartItemProductID"],
                itemName: props.state_cart[Number(props.index)]["cartItemProductName"],
                itemIMG: props.state_cart[Number(props.index)]["src"],
                itemPrice: props.state_cart[Number(props.index)]["cartItemPrice"],
                itemQuantity: 1
            } ;
            
            props.updateItems(props.state_cart, newObject)
        }
        
        // minus
        else if (event.target.id == 'minus') {
            const newObject2 = {
                itemID: props.state_cart[Number(props.index)]["cartItemProductID"],
                itemName: props.state_cart[Number(props.index)]["cartItemProductName"],
                itemIMG: props.state_cart[Number(props.index)]["src"],
                itemPrice: props.state_cart[Number(props.index)]["cartItemPrice"],
                itemQuantity: -1
            } ;
            props.deductItems(props.state_cart, newObject2);
        }
    };


    // **********   Case 'REMOVE' ; Update Redux state management   ***********

    const handleRemove = (event) => {

        // 1. Set new state in Parent Component
        props.removeMethod(Number(props.index));

        // 2. Dispatch Remove function
        const object = {
            itemID: props.state_cart[Number(props.index)]["cartItemProductID"],
            itemName: props.state_cart[Number(props.index)]["cartItemProductName"],
            itemIMG: props.state_cart[Number(props.index)]["src"],
            itemPrice: props.state_cart[Number(props.index)]["cartItemPrice"],
            itemQuantity: props.state_cart[Number(props.index)]["cartItemQuantity"],
            itemIndex: props.index
        } ;

        props.removeItems(object)


    }
    

    



    return (


    <div style={styles.main_container}>
            <div>
                <div style={styles.div1strow}>
                    <div style={styles.labelProductName}>{ ' Item: '}  {props.productName} </div>
                    <img src={props.productIMG} style={styles.img} />
                </div>

                <div style={styles.row_quantity_container}>
                    <div style={styles.row_quantity}>
                        <div style={styles.quantities}> {'Quantity: -'} </div>

                        <button style={styles.button} id="minus" name={props.index} onClick={handleIncrementDecrement}> - </button>

                        {props.state_cart[props.index]["cartItemQuantity"]}

                        <button style={styles.button} id="add" name={props.index} onClick={handleIncrementDecrement}> + </button>
                    </div>
                </div>

                <div style={styles.div3rdrow}>

                    <div style={styles.price}> {'  Price: '}     {props.state_cart[props.index]["cartItemPrice"]}    </div>

                    <div style={styles.subtotal}>
                        <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.sku.f_price_%22%7D%7D%5D"> {'  Subtotal: '} HKD $  {props.state_cart[props.index]['cartItemQuantity'] * props.state_cart[props.index]["cartItemPrice"]}.00 
                        </div>
                    </div>
                    
                    <button className="label cc-cart-remove-link" style={styles.remove} name={props.index} onClick={handleRemove}>Remove</button>


                    <a href="#"  value={props.productID}  role="" data-wf-bindings="%5B%7B%22data-commerce-sku-id%22%3A%7B%22type%22%3A%22ItemRef%22%2C%22filter%22%3A%7B%22type%22%3A%22identity%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItems%5B%5D.sku.id%22%7D%7D%5D" className="remove-button w-inline-block" data-wf-cart-action="remove-item" data-commerce-sku-id="" aria-label="Remove item from cart">
                        
                    </a>

                </div>
            </div>

    </div>
    );
}




const mapState_PerItemContainer = (state) => (
    // state = initState
    { state_cart: state.cart,
    
    
    }
)


const mapDispatchFunc_PerItemContainer  = (dispatch) => ({
    
    updateItems : (state, item) => {
        dispatch({type: 'UPDATE', state: state, payload: item})
    },
    
    deductItems : (state, item) => {
        dispatch({type: 'DEDUCT', state: state, payload: item})
    },

    removeItems : (item) => {
        dispatch({type: 'REMOVE', payload: item})
    },

    clearCart : (item) => {
        dispatch({type: 'CLEAR', payload: item})
    },

});



export default connect(mapState_PerItemContainer, mapDispatchFunc_PerItemContainer)(PerItemContainer);


//

        // set new state in Parent Component
        //props.removeMethod(Number(event.target.name));

        // dispatch to update props.state_cart via reducer
        //const object = {
            //...props.state_cart[props.index],
            //itemID: props.state_cart[props.index]["cartItemProductID"],
        //} ;

        //props.removeItems(props.state_cart, object)