import { React, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import CartItemContainer from '../comp/CartItemContainer';
import NavigationBar from '../NavigationBar';
import OrderDetailsContainer from '../comp/OrderDetailsContainer';
import StripeContainer from '../comp/StripeContainer';

const size = {
  mobile: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
}
export const mobile = (inner) => css`
  @media (max-width: ${size.mobile}) {
    ${inner};
  }
`;
export const tablet= (inner) => css`
  @media (min-width: ${size.tablet}) {
    ${inner};
  }
`;
export const desktop= (inner) => css`
  @media (min-width: ${size.desktop}) {
    ${inner};
  }
`;
export const laptop= (inner) => css`
  @media (min-width: ${size.laptop}) {
    ${inner};
  }
`;
const StyledDiv = styled.div`
    ${mobile(css`
    width:300px;
      `)};
      
    ${tablet(css`
    width:740px;
    `)};

    ${desktop(css`
    width:1000px;
    `)};
`;


const OrderDetailsPage = ( props ) => {


  const styles = {
    main_container: { display: 'flex', flexDirection: 'column', width: '95vw'},
    header_wrapper: { display: 'flex', flexDirection: 'row', marginLeft: '35px', width: '95vw'},
    
    add_order_container: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '65px',},
    button_confirm_container: { display: 'flex', flexDirection: 'row', width: '95vw'} ,
    button_confirm : { marginLeft: '30vw', marginTop: '65px', height: '45px', width: '140px', fontSize:'16px', fontWeight: 'bold',
    backgroundColor: '#e1d2c9', color: 'grey'},
    
    payment_input_form : { display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',  height: '45px', width: '95vw', fontSize:'16px', fontWeight: 'bold', marginTop: '40px', } ,
    button_confirm_payment: {  marginLeft: '15vw', width: '170px', fontSize:'16px', fontWeight: 'bold', backgroundColor: '#e1d2c9', color: 'grey', },
    payment_info_container : { marginLeft: '25vw', marginTop: '40px', marginBottom: '50px', width: '60vw' , backgroundColor: 'grey'},
    
    
    data: { display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '95vw', marginLeft: '50px', marginTop: '30px', paddingTop: '30px', marginBottom: '90px', },

    cart_button: { marginLeft: '1vw',  marginTop: '50px'},
    display_none: {display: 'none'},

    cart_container: { display: 'flex', flexDirection: 'column', scale: '95%', },
    cart_main_container: { display: 'flex', flexDirection: 'column', marginTop: '40px', },
    cart_items_container: { marginLeft: '45px'},

    cart_subtotal_header_container: { display: 'flex', flexDirection: 'column', marginTop: '2vh',  },
    cart_subtotal_description_container: { marginBottom: '10vh', },

    button_clearcart_checkout_container: {display: 'flex', flexDirection: 'column', },
    clear_cart: { height: '35%',},
    clear_cart_text: {  paddingTop: '0.1vw', paddingBottom: '0.3vw', },
    
    check_out_container: { } ,
    check_out: { width: '90%',  }

  }

    const { orderid } = useParams();

    var productID = props.db_products[props.index]["productID"]; 
    var productName = props.db_products[props.index]["productName"]; 
    var productPrice = props.db_products[props.index]["productPrice"];
    var productQuantity = props.quantity; var orderTotal = props.total; 
    var orderEmail = props.email; var orderAddress = props.address; 
    var pic = props.db_products[props.index]["imgURL"];

    const [ showItem, setItem ] = useState(false);


    var cartSubtotal =  0 ;
    for (let i=0; i < props.state_cart.length; i++) {
      cartSubtotal += props.state_cart[i]["cartItemQuantity"] * props.state_cart[i]["cartItemPrice"]
    }

    
    
    




return (

    <StyledDiv style={styles.main_container}>
      
        <div style={styles.header_wrapper}>
          <NavigationBar db_products={props.db_products} addMethod={props.addMethod} removeMethod={props.removeMethod}/>

            <div style={styles.cart_button} data-node-type="commerce-cart-wrapper" data-open-product="" data-wf-cart-type="rightSidebar"
            data-wf-page-link-href-prefix="" class="w-commerce-commercecartwrapper cart"  >
              
              
              <a href="#" data-node-type="commerce-cart-open-link" class="w-commerce-commercecartopenlink button cc-cart w-inline-block" role="button" aria-has-popup="dialog" aria-label="Open cart">
                
                <div class="w-inline-block">Cart</div>
                <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22Number%22%2C%22filter%22%3A%7B%22type%22%3A%22numberPrecision%22%2C%22params%22%3A%5B%220%22%2C%22numberPrecision%22%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.userItemsCount%22%7D%7D%5D" class="w-commerce-commercecartopenlinkcount cart-quantity">0</div>
                
              </a>
              
              
              <div data-node-type="commerce-cart-container-wrapper" style={styles.display_none} class="w-commerce-commercecartcontainerwrapper w-commerce-commercecartcontainerwrapper--cartType-rightSidebar">

              <div data-node-type="commerce-cart-container" role="dialog" class="w-commerce-commercecartcontainer cart-container" id="cartContainer" >
                        
                        
                        <div class="w-commerce-commercecartheader cart-header" >
                            <h4 class="heading-jumbo-tiny">your cart</h4>
                            <a href="#" data-node-type="commerce-cart-close-link" class="w-commerce-commercecartcloselink close-button w-inline-block" role="button" aria-label="Close cart">
                                <img src="https://assets.website-files.com/5c6eefaaeddf9248ac13bc72/5c6ef116eddf9216ae13c295_close-icon.svg" alt="" class="icon"/>
                            </a>
                        </div>


                        <div class="w-commerce-commercecartformwrapper" >

                            <div class="w-commerce-commercecartemptystate" style={styles.cart_container}>
                                <div class="paragraph-light">Selected Item(s): </div>

                                <div style={styles.cart_main_container}>

                                  <div style={styles.cart_items_container}>
                                    <CartItemContainer db_products={props.db_products} addMethod={props.addMethod} removeMethod={props.removeMethod} />
                                  </div>



                                  <div style={styles.cart_subtotal_header_container} data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd973" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd973&quot;]" class="w-commerce-commercecheckoutblockheader block-header" >
                                        <h4 data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd974" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd974&quot;]" class="heading-jumbo-tiny">Cart Subtotal</h4>
                                  </div>
                                    

                                    <div style={styles.cart_subtotal_description_container}data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd978" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd978&quot;]" class="w-commerce-commercecheckoutblockcontent block-content" >


                                        <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fd" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fd&quot;]" class="w-commerce-commercecheckoutordersummaryextraitemslist" >
                                          
                                          <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9f9" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9f9&quot;]" class="w-commerce-commercecheckoutsummarylineitem">
                                            
                                            <div>
                                              <label for="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd983" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd983&quot;]" class="w-commerce-commercecheckoutlabel">Subtotal</label>
                                            </div>
                                            
                                            <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.subtotal%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fc" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fc&quot;]">HKD$&nbsp;{cartSubtotal}.00&nbsp;</div>

                                          </div>
                                          
                                          


                                          <div data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd9fe_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd9fe_instance-0&quot;]" class="w-commerce-commercecheckoutordersummaryextraitemslistitem">
                                            
                                            <div>
                                              <label for="" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dd979" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dd979&quot;]" class="w-commerce-commercecheckoutlabel">Shipping Fee</label>
                                            </div>
                                            
                                            <div data-wf-bindings="%5B%7B%22innerHTML%22%3A%7B%22type%22%3A%22CommercePrice%22%2C%22filter%22%3A%7B%22type%22%3A%22price%22%2C%22params%22%3A%5B%5D%7D%2C%22dataPath%22%3A%22database.commerceOrder.extraItems%5B%5D.price%22%7D%7D%5D" data-w-id="992b7cac-1ae1-ef14-d4d9-dd177e5dda00_instance-0" data-wf-id="[&quot;992b7cac-1ae1-ef14-d4d9-dd177e5dda00_instance-0&quot;]">HKD$&nbsp;{props.shipping["standard"]}.00&nbsp;
                                            </div>

                                          </div>

                                        </div>







                                    </div>


                                    
                                      
                                      


                                  <div style={styles.button_clearcart_checkout_container}>

                                    <button aria-live="" style={styles.clear_cart} data-node-type="commerce-cart-error" class="w-commerce-commercecarterrorstate status-message cc-error-message">
                                      <p style={styles.clear_cart_text} class="w-cart-error-msg">CLEAR CART</p>
                                    </button>


                                    <div>
                                      <Link to={`/checkout/${orderid}`} >
                                        <button style= {styles.check_out}>
                                          <a href="/checkout" value="Continue to Checkout"  class="w-commerce-commercecartcheckoutbutton button" >Continue to Checkout</a>
                                        </button>
                                        </Link>
                                    </div>
                                    
                                  </div>


                                </div>
                                
                            </div>
                            
                            
                            
                            




                        </div>



              </div>

              </div>


            </div>
        </div>
        


          <OrderDetailsContainer db_products={props.db_products} index={props.index} quantity={props.quantity} price={props.price} total={props.total} address={props.address} email={props.email} />
          
          
          


          
          <div style={styles.payment_info_container}> { showItem ? <StripeContainer amount={props.total} orderID={orderid} shippingFee={props.shipping["standard"]} /> : <div></div> }
          </div>
          

          <div style={styles.payment_input_form}>
            <button style={styles.button_confirm_payment} type="submit" value= " " onClick={()=> setItem(true)}> Confirm Payment </button>
          </div>
          
          


          
          
          
          <CartItemContainer removeMethod={props.removeMethod} />





    </StyledDiv>
    
  

  );
};



const mapState_OrderDetailsPage = (state) => {
  return ({
    state_cart: state.cart,}
  )
};



export default connect(mapState_OrderDetailsPage)(OrderDetailsPage);