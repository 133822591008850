

import { Elements , stripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { React, useState, useEffect, } from 'react';
import { PaymentElement, LinkAuthenticationElement, useStripe, useElements, CardElement } from '@stripe/react-stripe-js'



function Holder(props) {
    
    // Initialize Stripe
    const STRIPE_PUBLISHABLE_KEY = "pk_test_51OOjwoJPUBzQwAMiuoZZZjMIrDVpDbLsFWCQx8KgLgErv4ETWWE096rkmrUuEvNjzyjLLWuaOpBzkodEPM7xBa0x00OLGlBn3B";

    const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

    const [clientSecret, setClientSecret] = useState(props.clientSecret);


    // 2. When page loaded, fetch and get the clientSecret from server's response

    //
    useEffect(() => {
      // Create PaymentIntent as soon as the page loads

      async function fetchPaymentIntent() {
        try {   
          const response = await fetch(
            'https://getmy3ds.azurewebsites.net/api/fetchPaymentIntent?code=Z8t20xK1toozmatryBdCh5c3nn3aeLPPhJQP5EEDXD92AzFuROWsOA%3D%3D',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                name: props.orderID,
                items: {
                  amount: (props.shippingFee + props.amount) * 100,
                },
              }),
            }
          );
  
          if (!response.ok) {
            throw new Error('Request failed with status: ' + response.status);
          }
  
          const responseData = await response.json();
          const { paymentIntent } = responseData;
  
          if (!paymentIntent) {
            throw new Error('Payment intent not found in the response data');
          }
  
          console.log(paymentIntent);
          console.log("paymentIntent.client_secret: " + paymentIntent.client_secret);
          setClientSecret(paymentIntent.client_secret);
          console.log('PaymentIntent created!');
        } catch (err) {
          console.error(err);
        }
      }
  
      // fetchPaymentIntent();


      setClientSecret(props.clientSecret);
    }, []);





    // 3. Pass the clientSecret as options as prop for <Element>


    const appearance = {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0570de',
        colorPrimaryText: '#262626',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',

        // See all possible variables below  
      },

    };

    const options = {
      clientSecret,
      appearance,
    };
  


    return (
        <div style={{width: '350px'}}>

            <div class="flex-item width-12">
                <div class="FormFieldGroup">
                    <div style={{opacity: '1'}} class="FormFieldGroup-labelContainer flex-container justify-content-space-between">
                        <label for="cardNumber-fieldset">
                            <span class="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">Card information</span>
                        </label>
                    </div>
                            
                    <fieldset class="FormFieldGroup-Fieldset" id="cardNumber-fieldset">
                            <div class="FormFieldGroup-container" id="cardNumber-fieldset-inner">
                                <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                                    <div class="FormFieldInput padding-right-120" style={{opacity: '1', display: 'flex',}} >

                                        <div style={{opacity: '1', display: 'flex',}}>

                                            <div class="CheckoutInputContainer">
                                                <span class="InputContainer" data-max="">
                                                    <input class="CheckoutInput CheckoutInput--tabularnums Input Input--empty" autocomplete="cc-number" autocorrect="off" spellcheck="false" id="cardNumber" name="cardNumber" type="text" inputmode="numeric" aria-label="Card number" placeholder="1234 1234 1234 1234" aria-invalid="false" data-1p-ignore="false" value=""/>
                                                </span>
                                            </div>

                                            <div class="FormFieldInput-Icons" style={{opacity: '1', display: 'flex', justifyContent: 'center'}}>
                                                <div style={{transform: 'none'}}>
                                                    <span class="FormFieldInput-IconsIcon is-visible">
                                                        <img src="https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg" alt="Visa" class="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                    </span>
                                                </div>

                                                <div style={{transform: 'none'}}>
                                                    <span class="FormFieldInput-IconsIcon is-visible">
                                                        <img src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" alt="MasterCard" class="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                    </span>
                                                </div>

                                    
                                                <div style={{transform: 'none'}}>
                                                    <span class="FormFieldInput-IconsIcon is-visible">
                                                        <img src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" alt="American Express" class="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                    </span>
                                                </div>
                                    
                                    
                                                <div class="CardFormFieldGroupIconOverflow">
                                            
                                                    <span class="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--visible" role="presentation">
                                                        <span class="FormFieldInput-IconsIcon" role="presentation">
                                                            <img src="https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg" alt="UnionPay" class="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                        </span>
                                                    </span>

                                                    <span class="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--invisible" role="presentation">
                                                        <span class="FormFieldInput-IconsIcon" role="presentation">
                                                            <img src="https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg" alt="JCB" class="BrandIcon" loading="lazy" fetchpriority="low"/>
                                                        </span>
                                                    </span>

                                                </div>



                                            </div>

                                        </div>


                                    </div>
                                </div>
                            
                                <div class="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childLeft FormFieldGroup-childBottom">
                                    <div class="FormFieldInput">
                                        <div class="CheckoutInputContainer">
                                            <span class="InputContainer" data-max="">
                                                <input class="CheckoutInput CheckoutInput--tabularnums Input Input--empty" autocomplete="cc-exp" autocorrect="off" spellcheck="false" id="cardExpiry" name="cardExpiry" type="text" inputmode="numeric" aria-label="Expiration" placeholder="MM / YY" aria-invalid="false" data-1p-ignore="false" value=""/>
                                            </span>
                                        </div>  
                                    </div>
                                </div>
                                        
                                        <div class="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childRight FormFieldGroup-childBottom">
                                            <div class="FormFieldInput has-icon padding-right-32">
                                                <div class="CheckoutInputContainer">
                                                    <span class="InputContainer" data-max="">
                                                        <input class="CheckoutInput CheckoutInput--tabularnums Input Input--empty" autocomplete="cc-csc" autocorrect="off" spellcheck="false" id="cardCvc" name="cardCvc" type="text" inputmode="numeric" aria-label="CVC" placeholder="CVC" aria-invalid="false" data-1p-ignore="false" value=""/>
                                                    </span>
                                                </div>
                                                        
                                                <div class="FormFieldInput-Icon is-loaded" data-testid="FormFieldInput-Icon">
                                                    <div>
                                                        <svg class="Icon Icon--md" focusable="false" viewBox="0 0 32 21" role="img" aria-label="CVC">
                                                            <title>CVC</title>
                                                            <g fill="none" fill-rule="evenodd">
                                                                <g class="Icon-fill">
                                                                    <g transform="translate(0 2)"><path d="M21.68 0H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2V9.47a5.98 5.98 0 0 1-3 1.45V11c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z" opacity=".2"></path><path d="M19.34 3H0v3h19.08a6.04 6.04 0 0 1 .26-3z" opacity=".3"></path></g><g transform="translate(18)"><path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM4.22 4.1h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33C8.68 4.64 7.85 4 6.65 4a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92H9.64v3.15c.4-.1.8-.17 1.2-.17z"></path></g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div style={{opacity: '0', height: '0px'}}>
                                            <span class="FieldError Text Text-color--red Text-fontSize--13">
                                                <span aria-hidden="true"></span>
                                            </span>
                                        </div>

                            </div>

                    </fieldset>
                </div>

            </div>


        </div>

    )
}


export default Holder;