import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import styled, { css } from 'styled-components';

const size = {
    mobile: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
}
export const mobile = (inner) => css`
    @media (max-width: ${size.mobile}) {
      ${inner};
    }
`;
export const tablet= (inner) => css`
    @media (min-width: ${size.tablet}) {
      ${inner};
    }
`;
export const desktop= (inner) => css`
    @media (min-width: ${size.desktop}) {
      ${inner};
    }
`;
export const laptop= (inner) => css`
    @media (min-width: ${size.laptop}) {
      ${inner};
    }
`;
  
const StyledDiv = styled.div`
      ${mobile(css`
      width:300px;
        `)};
        
      ${tablet(css`
      width:740px;
      `)};
  
      ${desktop(css`
      width:1000px;
      `)};
`;


const ProductList = ( props ) => {

const styles = {
    container: { backgroundColor: '#e1d2c9', display: 'flex', flexDirection: 'column', marginLeft: '2vw', width: '95vw'},
    heading: { color: '#7f7f7a' , fontSize: '24px', fontWeight: 'bold', paddingBottom: '35px',},
    name: { color: '#7f7f7a' , fontSize: '16px', fontWeight: 'bold', paddingBottom: '35px',},
    link: { textDecoration: 'none', margin :'15px'},
    button: { margin :'15px'}
};



const [ items, setItems ] = useState(props.state_cart);


//  To check local state, once props.state_cart updated
useEffect(() => { setItems(props.db_products); } , []);



const handleTabClick = (value) => {
};


return (

    <StyledDiv style={styles.container} className='second-component'>
        
        <h2 style={styles.heading}>Our Products</h2>


        <div style={{ overflowX: 'scroll', whiteSpace: 'nowrap' }} >
            
            {items && items.map( (item) => (

            <nav key={item.productID} style={{ display: 'inline-block', marginRight: '10px', cursor: 'pointer', }} onClick={() => handleTabClick(item.id)}>
                
                
                    <Link to={`/product/${item.id}/${item.productID}/${item.productName}` } style={styles.link} >
                        
                      <img src={item.imgURL} style={{ width: '250px', height: '250px', paddingBottom: '15px' }} />
                      <p style={styles.name}> {item.productName} </p>
                    
                    </Link>
                

            </nav>
            
            ))}

        
        </div>


    </StyledDiv>
        

    );
};

export default ProductList;



